import styled from '@emotion/styled';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useModal } from '@/context/Modal.context';
import {
  useQualityControlTool,
  MODAL_TYPES,
} from '@/context/Qualitycontrol.context';
import { QC_DECISION_STATE } from '@/helpers/states';
import { QC_DECISION_STATE_TYPE } from '@/types/QcStatus';
import { ErrorType } from '../QcPanel/QcPanel';

const CustomizedButton = styled(Button)`
  box-shadow: none;
  margin: 1rem;
  padding: 0.5rem 2rem;
`;

interface QcSubmitButtonProps {
  isWeightHallmarkRequired: boolean;
  isWatchFunctionedRequired: boolean;
  setErrors: (v: ErrorType[]) => void;
}

export default function QcSubmitButtons({
  setErrors,
  isWeightHallmarkRequired,
  isWatchFunctionedRequired,
}: QcSubmitButtonProps) {
  const { t } = useTranslation();

  const { setModalInfo } = useModal();

  const { defects, weight, hallmarks, isWorkingWatch } =
    useQualityControlTool();

  const { qcData } = useQualityControlTool();

  const isNotForSale = !!qcData?.product?.notForSaleReasons.length;

  const isProhibited = defects.find((defect) => defect.isProhibited);

  const validate = (callback: Function) => {
    const errors: ErrorType[] = [];

    if (isWeightHallmarkRequired) {
      if (!weight) {
        errors.push({ field: 'weight', message: t('QC_V2.DEFECT.REQUIRED') });
      }
      if (!hallmarks) {
        errors.push({
          field: 'hallmarks',
          message: t('QC_V2.DEFECT.REQUIRED'),
        });
      }
    }

    if (isWatchFunctionedRequired) {
      if (isWorkingWatch == null) {
        errors.push({ field: 'watch', message: t('QC_V2.DEFECT.REQUIRED') });
      }
    }

    if (errors.length) {
      setErrors(errors);
      return;
    }

    callback();
  };

  const openSubmitModal = ({ result }: { result: QC_DECISION_STATE_TYPE }) => {
    setModalInfo({
      modalType: MODAL_TYPES.QC_SUBMIT,
      modalInfo: {
        qcResult: result,
      },
    });
  };

  return (
    <Box
      component="footer"
      sx={(theme) => ({
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.paper,
      })}
    >
      {!isNotForSale && !isProhibited && (
        <CustomizedButton
          data-testid="qc_ok_button"
          variant="contained"
          size="large"
          disabled={isNotForSale || isProhibited || !!defects.length}
          sx={{ mr: 2, backgroundColor: '#4CAF50' }}
          onClick={() => {
            validate(() =>
              openSubmitModal({
                result: QC_DECISION_STATE.ACCEPT,
              })
            );
          }}
        >
          {t('QC_V2.MAIN.QC_OK')}
        </CustomizedButton>
      )}
      {!isNotForSale && !isProhibited && (
        <CustomizedButton
          data-testid="qc_nego_button"
          variant="contained"
          size="large"
          disabled={isNotForSale || isProhibited || !defects.length}
          sx={{ backgroundColor: '#FFA000' }}
          onClick={() => {
            validate(() =>
              openSubmitModal({
                result: QC_DECISION_STATE.NEGOTIATE,
              })
            );
          }}
        >
          {t('QC_V2.MAIN.QC_NEGO')}
        </CustomizedButton>
      )}

      <CustomizedButton
        data-testid="qc_ko_button"
        variant="contained"
        size="large"
        disabled={!isProhibited && !defects.length}
        sx={{ backgroundColor: '#F44336' }}
        onClick={() => {
          validate(() =>
            openSubmitModal({
              result: QC_DECISION_STATE.REJECT,
            })
          );
        }}
      >
        {t('QC_V2.MAIN.QC_KO')}
      </CustomizedButton>

      <CustomizedButton
        data-testid="on_hold_button"
        variant="outlined"
        size="large"
        sx={{
          color: '#2196F3',
        }}
        onClick={() => {
          openSubmitModal({
            result: QC_DECISION_STATE.HOLD,
          });
        }}
      >
        {t('QC_V2.MAIN.QC_ON_HOLD')}
      </CustomizedButton>
    </Box>
  );
}
