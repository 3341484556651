import { TFunction } from 'i18next';
import { Theme } from '@/components/Theme';
import { LoginUser } from '@/context/Authentication.context';
import { AgentInfoType } from '@/types/Agent';
import { PRODUCT_MATERIAL } from '@/types/ProductInfo';

export enum QC_PRODUCT_STATE {
  ACCEPTED = 'accepted',
  BEING_CONTROLLED = 'being_controlled',
  BEING_NEGOTIATED = 'being_negotiated',
  QC_EXIT = 'qc_exit',
  NEGOTIATION_ACCEPTED = 'negotiation_accepted',
  NEGOTIATION_REJECTED = 'negotiation_rejected',
  PENDING_CONTROL = 'pending_control',
  REJECTED = 'rejected',
  HELD = 'held',
}

export const QC_PRODUCT_DONE_STATE = [
  QC_PRODUCT_STATE.BEING_NEGOTIATED,
  QC_PRODUCT_STATE.ACCEPTED,
  QC_PRODUCT_STATE.REJECTED,
  QC_PRODUCT_STATE.NEGOTIATION_REJECTED,
  QC_PRODUCT_STATE.NEGOTIATION_ACCEPTED,
];

export enum QC_DECISION_STATE {
  CONTROL = 'control',
  ACCEPT = 'accept',
  NEGOTIATE = 'negotiate',
  REJECT = 'reject',
  HOLD = 'hold',
  TAKE_OVER_CONTROL = 'take_over_control',
}

export enum QC_PRODUCT_STATUS {
  OK = 'OK',
  KO = 'KO',
  NEGO = 'NEGO',
}

/*
  TODO: Check maintaining types of state/status
  - Status: OK / KO / NEGO
  - Decision state (FE submit this value): accept / reject / nego / ...
  - Response state (BE response this value): accepted / rejected / being_negotiated / ...
*/

export const QC_PRODUCT_STATUS_MAPPING: Record<QC_PRODUCT_STATE, string> = {
  [QC_PRODUCT_STATE.ACCEPTED]: QC_PRODUCT_STATUS.OK,
  [QC_PRODUCT_STATE.REJECTED]: QC_PRODUCT_STATUS.KO,
  [QC_PRODUCT_STATE.BEING_NEGOTIATED]: QC_PRODUCT_STATUS.NEGO,
  [QC_PRODUCT_STATE.NEGOTIATION_ACCEPTED]: QC_PRODUCT_STATUS.NEGO,
  [QC_PRODUCT_STATE.NEGOTIATION_REJECTED]: QC_PRODUCT_STATUS.NEGO,
  [QC_PRODUCT_STATE.BEING_CONTROLLED]: '',
  [QC_PRODUCT_STATE.QC_EXIT]: '',
  [QC_PRODUCT_STATE.PENDING_CONTROL]: '',
  [QC_PRODUCT_STATE.HELD]: '',
};

// API response state to submission state
export const QC_RESPONSE_STATE_TO_DECISION_STATE_MAPPING: Partial<
  Record<QC_PRODUCT_STATE, QC_DECISION_STATE>
> = {
  [QC_PRODUCT_STATE.ACCEPTED]: QC_DECISION_STATE.ACCEPT,
  [QC_PRODUCT_STATE.REJECTED]: QC_DECISION_STATE.REJECT,
  [QC_PRODUCT_STATE.BEING_NEGOTIATED]: QC_DECISION_STATE.NEGOTIATE,
  [QC_PRODUCT_STATE.HELD]: QC_DECISION_STATE.HOLD,
};

// Decision state to shown status state
export const QC_DECISION_STATE_TO_STATUS_MAPPING: Partial<
  Record<QC_DECISION_STATE, QC_PRODUCT_STATUS>
> = {
  [QC_DECISION_STATE.ACCEPT]: QC_PRODUCT_STATUS.OK,
  [QC_DECISION_STATE.REJECT]: QC_PRODUCT_STATUS.KO,
  [QC_DECISION_STATE.NEGOTIATE]: QC_PRODUCT_STATUS.NEGO,
};

export const LOCALE = 'en-GB';

export const getStatusMapping = (
  t: TFunction<'translation', undefined>
): Record<
  string,
  {
    label: string;
    color: string;
    background?: string;
  }
> => {
  return {
    [QC_PRODUCT_STATE.REJECTED]: {
      label: 'KO',
      color: Theme.palette.error.main,
    },
    [QC_PRODUCT_STATE.ACCEPTED]: {
      label: t('QC_V2.MAIN.OK')?.toUpperCase(),
      color: Theme.palette.success.main,
    },
    [QC_PRODUCT_STATE.HELD]: {
      label: t('QC_V2.MAIN.HELD')?.toUpperCase(),
      color: Theme.palette.info.main,
    },
    [QC_PRODUCT_STATE.BEING_NEGOTIATED]: {
      label: t('QC_V2.MAIN.QC_NEGO')?.toUpperCase(),
      color: Theme.palette.warning.main,
    },
    [QC_PRODUCT_STATE.NEGOTIATION_ACCEPTED]: {
      label: t('QC_V2.MAIN.NEGOTIATION_ACCEPTED')?.toUpperCase(),
      color: Theme.palette.warning.main,
    },
    [QC_PRODUCT_STATE.NEGOTIATION_REJECTED]: {
      label: t('QC_V2.MAIN.NEGOTIATION_REJECTED')?.toUpperCase(),
      color: Theme.palette.warning.main,
    },
    [QC_PRODUCT_STATE.BEING_CONTROLLED]: {
      label: t('QC_V2.MAIN.ON_GOING')?.toUpperCase(),
      color: Theme.palette.common.white,
      background: Theme.palette.grey[600],
    },
  };
};

export const PRODUCT_TOOK_OVER_ERROR = 'qc.another_agent_controlling';
export const PRODUCT_RELISTED_ERROR = 'qc.product_already_relisted';
export const BEING_CONTROL_AN_ITEM_ERROR =
  'qc.agent_already_controlling_another_instance';

export const QC_REQUIRED_JEWELRY_MATERIALS = [
  // EN
  PRODUCT_MATERIAL.EN.GOLD.toLowerCase(),
  PRODUCT_MATERIAL.EN.WHITE_GOLD.toLowerCase(),
  PRODUCT_MATERIAL.EN.PINK_GOLD.toLowerCase(),
  PRODUCT_MATERIAL.EN.YELLOW_GOLD.toLowerCase(),
  PRODUCT_MATERIAL.EN.SILVER.toLowerCase(),
  PRODUCT_MATERIAL.EN.PLATINUM.toLowerCase(),
  // FR
  PRODUCT_MATERIAL.FR.GOLD.toLowerCase(),
  PRODUCT_MATERIAL.FR.WHITE_GOLD.toLowerCase(),
  PRODUCT_MATERIAL.FR.PINK_GOLD.toLowerCase(),
  PRODUCT_MATERIAL.FR.YELLOW_GOLD.toLowerCase(),
  PRODUCT_MATERIAL.FR.SILVER.toLowerCase(),
  PRODUCT_MATERIAL.FR.PLATINUM.toLowerCase(),
];

export const isControlledByCurrentAgent = (
  agent: AgentInfoType,
  loginUser: LoginUser
) => agent.email === loginUser.email;

export const isBeingControl = (state: QC_PRODUCT_STATE) =>
  state === QC_PRODUCT_STATE.BEING_CONTROLLED;

export const isPendingControl = (state: QC_PRODUCT_STATE) =>
  state === QC_PRODUCT_STATE.PENDING_CONTROL;

export const isOnHold = (state: QC_PRODUCT_STATE) =>
  state === QC_PRODUCT_STATE.HELD;

export const isDoneQC = (state: QC_PRODUCT_STATE) =>
  QC_PRODUCT_DONE_STATE.includes(state);

export const isExitQC = (state: QC_PRODUCT_STATE) =>
  state === QC_PRODUCT_STATE.QC_EXIT;

export const isPending2ndRound = (
  hasMultiQC: boolean,
  state: QC_PRODUCT_STATE
) => hasMultiQC && isPendingControl(state);
