import { useMutation } from '@tanstack/react-query';
import { MUTATE_PRODUCT } from '@/helpers/query-keys';
import { putQcState } from '@/services/qc.service';
import { CustomError } from '@/types/AxiosError';
import { QcInfoType, UpdateQcState } from '@/types/QcInfo';

const useUpdateQcState = () => {
  return useMutation<{ data: QcInfoType }, CustomError, UpdateQcState>({
    mutationKey: [MUTATE_PRODUCT],
    mutationFn: (props: UpdateQcState) => putQcState(props),
  });
};

export default useUpdateQcState;
