import {
  Autocomplete,
  AutocompleteProps,
  Box,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Stack } from '@mui/system';
import {
  Controller,
  FieldPath,
  FieldValues,
  useController,
} from 'react-hook-form';
import { FormField } from '@/types/interfaces/form';
import ErrorMessage from './ErrorMessage';

type SelectProps<T> = Partial<
  AutocompleteProps<T, undefined, undefined, undefined>
>;

interface FormAutocompleteProps<
  T,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends FormField<TFieldValues, TName> {
  autocompleteProps?: SelectProps<T>;
  textFieldProps?: TextFieldProps;
}

const FormAutocomplete = <
  T,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  label,
  name,
  control,
  rules,
  autocompleteProps,
  textFieldProps,
}: FormAutocompleteProps<T, TFieldValues, TName>) => {
  const {
    fieldState: { error },
  } = useController({ control, name });

  const { options = [] } = autocompleteProps || {};

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, ref, ...field } }) => {
        return (
          <Stack>
            <Autocomplete
              disablePortal
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={rules?.required ? `${label} *` : label}
                  inputRef={ref}
                  {...textFieldProps}
                />
              )}
              renderOption={(props, option) => {
                const label = (option as { label: string }).label;
                const { key, ...optionProps } = props;
                return (
                  <Box
                    key={key}
                    component="li"
                    {...optionProps}
                    dd-custom-action={`${name.toLocaleUpperCase()}: ${label || 'Unknown (Cannot get selected value)'}`}
                  >
                    {label}
                  </Box>
                );
              }}
              onChange={(_, data) => onChange(data)}
              value={value}
              {...field}
              {...autocompleteProps}
              sx={(theme) => ({
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: error ? theme.palette.error.main : 'inherit',
                },
              })}
            />
            <ErrorMessage error={error} />
          </Stack>
        );
      }}
    />
  );
};

export default FormAutocomplete;
