import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '@/context/Authentication.context';
import { QualityControlProvider } from '@/context/Qualitycontrol.context';
import { toHomeScreen } from '@/helpers/routing';
import { isBeingControl, isPending2ndRound } from '@/helpers/states';
import { useProduct } from '@/hooks/use-product';
import { useQcMode } from '@/hooks/use-qcMode';
import { useQuery } from '@/hooks/use-url';
import QcToolContent from './QcToolContent';

const QualityControlTool = () => {
  const { id } = useParams();

  const urlParams = useQuery();

  const qcRoundParam = urlParams.get('qcRound');

  const navigate = useNavigate();

  const { mode } = useQcMode();

  const { user: loginUser } = useAuthContext();

  const { qcQueryData, setProductId, productId, hasMultiQC } = useProduct();

  const [qcRound, setQcRound] = useState(1);

  useEffect(() => {
    if (id && id !== productId) {
      setProductId(id);
    }
  }, [productId, id, setProductId]);

  useEffect(() => {
    if (qcQueryData && qcQueryData.length) {
      const latestQc = qcQueryData[0];

      // Redirect to Home screen when user no longer related to this product
      if (mode !== 'view') {
        const agents = qcQueryData.map((d) => d.agent?.email || '');

        if (
          !isBeingControl(latestQc.state) ||
          !agents.includes(loginUser.email)
        ) {
          setProductId('');
          toHomeScreen(navigate);
        }
      }
      const selectedRound = qcRoundParam
        ? parseInt(qcRoundParam) - 1
        : latestQc.qualityControlRound;

      setQcRound(selectedRound || 1);
    }
  }, [qcQueryData]);

  if (!qcQueryData || !qcQueryData.length) return null;

  if (isPending2ndRound(hasMultiQC, qcQueryData[0].state)) {
    /*
      View product has multi QCs case,
      if the latest one hasn't been scanned yet show only last QC record
     */
    return (
      <QualityControlProvider data={qcQueryData[qcQueryData.length - 1]}>
        <QcToolContent selectQcRound={setQcRound} />
      </QualityControlProvider>
    );
  }

  return (
    <Box position="relative">
      {qcQueryData.map((d, idx) => {
        const isShown = qcRound === d.qualityControlRound;
        return (
          <Box
            key={`page-${d.uuid}`}
            visibility={isShown ? 'visible' : 'hidden'}
            position="absolute"
          >
            <QualityControlProvider data={qcQueryData[idx]}>
              <QcToolContent selectQcRound={setQcRound} />
            </QualityControlProvider>
          </Box>
        );
      })}
    </Box>
  );
};

export default QualityControlTool;
