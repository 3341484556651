import styled from '@emotion/styled';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '@/context/Modal.context';
import {
  SetDefectPayloadType,
  useQualityControlTool,
} from '@/context/Qualitycontrol.context';
import { buildImageUrl } from '@/helpers/image';
import { useCheckPoints } from '@/hooks/use-checkpoints';
import { useQcMode } from '@/hooks/use-qcMode';
import { PHOTO_TYPE } from '@/types/interfaces/defect-details';
import ConfirmModal from '../DefectDetailModal/ConfirmModal';
import PhotoBox from '../DefectDetailModal/PhotoBox';

const TableHeadCell = styled(TableCell)`
  font-weight: bold;
  padding: 1rem;
`;

const TableBodyCell = styled(TableCell)`
  padding: 0.5rem 1rem;
`;

const DefectList = () => {
  const { t } = useTranslation();

  const { qcData: { uuid = '', record } = {} } = useQualityControlTool();

  const { isViewMode } = useQcMode();

  const { checkPointList: currentCheckpoints } = useCheckPoints(uuid);

  const { defects: currentDefects, deleteDefect } = useQualityControlTool();
  const { setModalInfo } = useModal();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [deletedId, setDeletedId] = useState<number>();

  const checkpoints = isViewMode
    ? record?.checkpointRecords?.map((r) => r.checkpoint)
    : currentCheckpoints;

  const defects = useMemo(() => {
    if (isViewMode && !record) return [];

    return isViewMode
      ? record!.checkpointRecords.reduce(
          (acc, curr) => {
            if (!curr.defectRecords.length) return acc;

            const result = curr.defectRecords.map((defect) => ({
              checkPointId: curr.checkpoint.id,
              ...defect,
            }));

            acc.push(...result);

            return acc;
          },
          [] as SetDefectPayloadType['data']
        )
      : currentDefects;
  }, [isViewMode, record, currentDefects]);

  const tableData = useMemo(() => {
    if (!defects.length || !checkpoints) return [];

    const result = defects.map((d) => {
      const checkPoint = checkpoints?.find((c) => c.id === d.checkPointId);

      return {
        id: d.id,
        checkPointId: checkPoint?.id,
        checkPointName: checkPoint?.name || 'N/A',
        checkPoint: checkPoint,
        defectType: d.defectType,
        actualValue: d.actualValue,
        isProhibited: d.isProhibited,
        photos: d.photos,
        submittedDefect: d,
      };
    });

    return result;
  }, [defects, checkpoints]);

  const handleDelete = () => {
    if (deletedId === undefined) return;
    deleteDefect({ id: deletedId });
    setShowDeleteConfirm(false);
  };

  const getDefactActualValue = (actualValue: string) => {
    const _actualValue = actualValue.split('\n').filter((_) => !!_);

    return _actualValue?.length ? _actualValue : null;
  };

  return (
    <Stack>
      <Table
        data-testid="defect_list_table"
        sx={{ minWidth: 650, my: 0.5 }}
        size="small"
        aria-label="check points table"
      >
        <TableHead>
          <TableRow>
            <TableHeadCell>{t('QC_V2.MAIN.CHECKPOINTS')}</TableHeadCell>
            <TableHeadCell>{t('QC_V2.MAIN.DEFECT')}</TableHeadCell>
            <TableHeadCell>{t('QC_V2.MAIN.ACTUAL_VALUE')}</TableHeadCell>
            <TableHeadCell align="center">
              {t('QC_V2.MAIN.PHOTOS')}
            </TableHeadCell>
            <TableHeadCell align="center">
              {t('QC_V2.MAIN.ACTIONS')}
            </TableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {tableData.map((d) => {
            return (
              <TableRow key={d.id}>
                <TableBodyCell>
                  <Stack>
                    <Typography variant="body2" fontWeight="bold">
                      {d.checkPointName}
                    </Typography>

                    {!!d.isProhibited && (
                      <Typography
                        variant="body2"
                        color={(theme) => theme.palette.error.main}
                      >
                        {`(${t('QC_V2.MAIN.PROHIBITED')})`}
                      </Typography>
                    )}
                  </Stack>
                </TableBodyCell>

                <TableBodyCell>{d.defectType?.name || '-'}</TableBodyCell>

                <TableBodyCell sx={{ width: '30%' }}>
                  <List dense sx={{ listStyleType: 'disc' }}>
                    {getDefactActualValue(d.actualValue || '')?.map(
                      (value, index) => (
                        <ListItem
                          key={index}
                          sx={{
                            display: 'list-item',
                            position: 'relative',
                            left: '11px',
                            padding: '0',
                          }}
                        >
                          <ListItemText>{value}</ListItemText>
                        </ListItem>
                      )
                    ) || '-'}
                  </List>
                </TableBodyCell>

                <TableBodyCell>
                  {d.photos?.length ? (
                    <Stack gap={1} alignItems="center">
                      {d.photos.map((p, idx) => {
                        let photoUrl = '';

                        const [imgType, imgName] = p.path.split('/');

                        photoUrl = buildImageUrl({
                          path: imgName,
                          type: imgType as PHOTO_TYPE,
                        });

                        return (
                          <PhotoBox
                            key={`${idx}-${d.id}-${imgName}`}
                            imgUrl={photoUrl}
                            annotations={p.annotations}
                            width={170}
                            height={170}
                          />
                        );
                      })}
                    </Stack>
                  ) : (
                    '-'
                  )}
                </TableBodyCell>

                <TableBodyCell>
                  <Stack>
                    <Button
                      onClick={() => {
                        setModalInfo({
                          modalType: 'DEFECT_CREATION',
                          modalInfo: {
                            checkpointName: d.checkPointName,
                            checkpointId: d.checkPointId,
                            defectId: d.submittedDefect.id,
                            viewMode: true,
                          },
                        });
                      }}
                    >
                      {t('QC_V2.MAIN.VIEW')}
                    </Button>
                    <Button
                      disabled={isViewMode}
                      color="error"
                      onClick={() => {
                        setDeletedId(d.id);
                        setShowDeleteConfirm(true);
                      }}
                    >
                      {t('QC_V2.MAIN.DELETE')}
                    </Button>
                  </Stack>
                </TableBodyCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {!tableData.length && (
        <Stack flexDirection="row" justifyContent="center" mt={3}>
          <Typography>{t('QC_V2.NO_DATA')}</Typography>
        </Stack>
      )}

      {showDeleteConfirm && (
        <ConfirmModal
          description={t('QC_V2.MAIN.CONFIRM_DELETE_DEFECT')}
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteConfirm(false)}
        />
      )}
    </Stack>
  );
};

export default DefectList;
