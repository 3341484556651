import { Trans } from 'react-i18next';
import { Theme } from '../Theme';

export default function NegoModalContent() {
  return (
    <Trans i18nKey="QC_V2.MAIN.CONFIRM_NEGO">
      The item is not fully compiled to the description, but worth for
      <strong key="confirm_nego" style={{ color: Theme.palette.warning.main }}>
        Nego
      </strong>
    </Trans>
  );
}
