import { Grid } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Control, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { checkRequired } from '@/helpers/defect-form';
import { useGetDefects } from '@/hooks/defect-details/use-get-defects';
import { REQUIRED_FIELD, SizeType } from '@/types/interfaces/defect-details';
import { FormSelectOption, SelectOptions } from '@/types/interfaces/form';
import FormAutocomplete from '../../Common/Form/FormAutocomplete';
import { DynamicFormValues } from '../DefectDetails';

interface SizeFormProps {
  control: Control<DynamicFormValues>;
  selectOptions: SelectOptions;
  disabled: boolean;
  isRequired?: boolean;
}

const SizeForm = ({
  control,
  selectOptions,
  disabled,
  isRequired,
}: SizeFormProps) => {
  const { t } = useTranslation();

  const { qcData: { uuid = '' } = {} } = useQualityControlTool();

  const { data: defects } = useGetDefects(uuid);

  const { setValue, watch, getFieldState } =
    useFormContext<DynamicFormValues>();

  const selectedDefect = watch('defect');

  const isCategoryDefect = !!selectedDefect?.optionalFields?.includes(
    REQUIRED_FIELD.CATEGORY
  );

  const { field: sizeTypeField } = useController({
    name: 'sizeType',
    control,
  });

  const { field: categoryField } = useController({
    name: 'category',
    control,
  });

  const selectedType = sizeTypeField.value?.value;
  const selectedCategory = categoryField.value;

  const sizeTypeState = getFieldState('sizeType');

  useEffect(() => {
    if (sizeTypeState.isDirty) {
      setValue('size', null);
    }
  }, [selectedType, sizeTypeState.isDirty]);

  const categorySizeType = useMemo(() => {
    return defects?.categorySizeTypes.find(
      (s) => s.categoryId.toString() === selectedCategory?.legacyId
    );
  }, [defects, selectedCategory]);

  const sizeTypeOptions: FormSelectOption[] = useMemo(() => {
    if (isCategoryDefect) {
      return categorySizeType
        ? categorySizeType.sizes?.map((s) => ({
            label: s.type,
            value: s.type,
          }))
        : [];
    }

    return selectOptions.sizeTypes;
  }, [isCategoryDefect, categorySizeType, defects]);

  const sizeTypeValueOptions: FormSelectOption[] = useMemo(() => {
    let sizeType: SizeType | undefined;

    if (isCategoryDefect) {
      sizeType = categorySizeType?.sizes?.find((s) => s.type === selectedType);
    } else {
      sizeType = defects?.sizeTypes?.find((s) => s.type === selectedType);
    }

    if (!sizeType || !sizeType?.values?.length) return [];

    return sizeType.values.map((v) => ({
      label: v,
      value: v,
    }));
  }, [isCategoryDefect, categorySizeType, selectedType, defects]);

  const required = isRequired ? t('QC_V2.DEFECT.REQUIRED') : undefined;

  const isHidden = !isCategoryDefect && !selectOptions.sizeTypes.length;

  if (isHidden) return null;

  return (
    <Grid container spacing={2.5} item>
      <Grid item xs={6}>
        <FormAutocomplete
          data-testid="size_type_combobox"
          control={control}
          name="sizeType"
          label={t('QC_V2.DEFECT.SIZE_TYPE')}
          rules={{
            required: checkRequired(required, sizeTypeOptions),
          }}
          autocompleteProps={{
            options: sizeTypeOptions,
            disabled: disabled,
            isOptionEqualToValue: (option, value) =>
              option.value === value.value,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormAutocomplete
          data-testid="size_value_combobox"
          control={control}
          name="size"
          label={t('QC_V2.DEFECT.SIZE')}
          rules={{
            required: checkRequired(required, sizeTypeValueOptions),
          }}
          autocompleteProps={{
            options: sizeTypeValueOptions,
            disabled: disabled,
            isOptionEqualToValue: (option, value) =>
              option.value === value.value,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SizeForm;
