import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import AdditionalInfo from '@/components/AdditionalInfo/AdditionalInfo';
import QcSubmitButtons from '@/components/QcSubmitButtons/QcSubmitButtons';
import ScrollableTabs from '@/components/ScrollableTabs/ScrollableTabs';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { QC_REQUIRED_JEWELRY_MATERIALS } from '@/helpers/states';
import { useProduct } from '@/hooks/use-product';
import { useQcMode } from '@/hooks/use-qcMode';
import { PRODUCT_TYPES } from '@/types/ProductInfo';
import PanelHeader from './PanelHeader';

export type ErrorType = {
  field: 'weight' | 'hallmarks' | 'watch';
  message: string;
};

interface QcPanelProps {
  selectQcRound: (round: number) => void;
}

export const QcPanel = ({ selectQcRound }: QcPanelProps) => {
  const { isViewMode } = useQcMode();

  const { hasMultiQC } = useProduct();

  const { qcData } = useQualityControlTool();

  const { product } = qcData || {};

  const [errors, setErrors] = useState<ErrorType[]>();

  const isJewelry =
    !!product &&
    product.type?.toLowerCase() === PRODUCT_TYPES.JEWELRY.toLowerCase();

  const isWatch =
    !!product &&
    product.type?.toLowerCase() === PRODUCT_TYPES.WATCH.toLowerCase();

  const isWeightHallmarkRequired =
    (isJewelry || isWatch) &&
    QC_REQUIRED_JEWELRY_MATERIALS.includes(product?.material?.toLowerCase());

  const isFirstQC = hasMultiQC && !!qcData && qcData.qualityControlRound === 1;

  const showButtons = !isViewMode && !isFirstQC;

  return (
    <>
      <div>
        <PanelHeader selectQcRound={selectQcRound} />
      </div>
      <Box display="flex" flexDirection="column">
        <Grid container mb={3}>
          <Grid item md={8}>
            <ScrollableTabs />
          </Grid>
          <Grid
            item
            md={4}
            sx={{
              mt: 3,
              height: 'calc(100vh - 16rem)',
              overflowY: 'scroll',
              scrollbarWidth: 'thin',
            }}
          >
            <AdditionalInfo
              showHallMarksAndWeight={isJewelry || isWatch}
              showWatchFunctioned={isWatch}
              isWeightHallmarkRequired={isWeightHallmarkRequired}
              errors={errors}
            />
          </Grid>
        </Grid>

        {showButtons && (
          <QcSubmitButtons
            isWeightHallmarkRequired={isWeightHallmarkRequired}
            isWatchFunctionedRequired={isWatch}
            setErrors={setErrors}
          />
        )}
      </Box>
    </>
  );
};
