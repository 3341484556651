import { useMemo } from 'react';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { isBeingControl as checkBeingControl } from '@/helpers/states';
import { useQuery } from '@/hooks/use-url';

export const useQcMode = () => {
  const { qcData: { state } = {} } = useQualityControlTool();

  const urlParams = useQuery();

  const mode = urlParams.get('mode');

  const isBeingControl = useMemo(() => {
    if (!state) return false;
    return checkBeingControl(state);
  }, [state]);

  const isViewMode = mode === 'view' || !isBeingControl;

  return {
    mode,
    isViewMode,
  };
};
