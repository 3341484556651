import { useContext } from 'react';
import { ProductContext } from '@/context/Product.context';

export const useProduct = () => {
  const {
    setProductId,
    productId,
    count,
    hasMultiQC,
    refetchQcQuery,
    qcQueryData,
    qcQueryError,
    isQcQueryFetching,
    setQcSubmitted,
    isQcSubmitted,
    toast,
    setToast,
    qcRounds,
    setQcRounds,
  } = useContext(ProductContext);

  return {
    setProductId,
    refetchQcQuery,
    productId,
    count,
    hasMultiQC,
    qcQueryData,
    qcQueryError,
    isQcQueryFetching,
    isQcSubmitted,
    setQcSubmitted,
    toast,
    setToast,
    qcRounds,
    setQcRounds,
  };
};
