import { datadogRum } from '@datadog/browser-rum';
import { useQueryClient } from '@tanstack/react-query';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '@/components/Modal/ErrorModal';
import QcModal from '@/components/Modal/Modal';
import { useModal } from '@/context/Modal.context';
import {
  MODAL_TYPES,
  useQualityControlTool,
} from '@/context/Qualitycontrol.context';
import { SCAN_PRODUCT } from '@/helpers/query-keys';
import { RoutesMapping } from '@/helpers/routing';
import {
  PRODUCT_TOOK_OVER_ERROR,
  QC_DECISION_STATE,
  QC_RESPONSE_STATE_TO_DECISION_STATE_MAPPING,
} from '@/helpers/states';
import { getToast } from '@/helpers/toast';
import { parseDecimal } from '@/helpers/unit';
import { useCheckPoints } from '@/hooks/use-checkpoints';
import { useProduct } from '@/hooks/use-product';
import useUpdateQcState from '@/hooks/use-update-qc-state';
import { AddItemStatusType } from '@/types/AdditionalItem';
import { CheckpointStatueType } from '@/types/Checkpoint';
import { QcInfoType } from '@/types/QcInfo';
import { StandbyReasonType } from '@/types/StandbyReasons';
import GuidingContent from './GuidingContent';
import KoModalContent from './KoModalContent';
import NegoModalContent from './NegoModalContent';
import OkModalContent from './OkModalContent';
import OnHoldModalContent from './OnHoldModalContent';
import ProhibitionKoContent from './ProhibitionKoContent';
import SecondQcNotice from './SecondQcNotice';

export default function QcSubmitModal() {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { hasMultiQC, qcQueryData, setToast, setQcSubmitted, setProductId } =
    useProduct();

  const firstQc = qcQueryData?.length
    ? qcQueryData[qcQueryData.length - 1]
    : undefined;

  const {
    qcData,
    additionalItems,
    refurbishment,
    isWorkingWatch,
    weight,
    hallmarks,
    agentComment,
    agentPublicComment,
    defects,
    qcPhotos,
  } = useQualityControlTool();

  const [submissionError, setSubmissionError] = useState<string | null>(null);

  const { modal, setModalInfo } = useModal();

  const {
    mutate: updateQcState,
    isPending: isSubmitting,
    isSuccess: isSubmitted,
  } = useUpdateQcState();

  const [isChildDialogOpen, setChildDialogOpen] = useState(false);

  const { uuid, record: qcRecord } = (qcData as QcInfoType) || {};

  const { checkPointList } = useCheckPoints(uuid);
  const updatedQcDataRef = useRef<QcInfoType>({} as QcInfoType);

  const navigate = useNavigate();
  const childRef = useRef<HTMLDivElement>(null);

  const {
    modalType,
    modalInfo: { qcResult, state: qcState },
  } = modal || {};

  const isProhibited = qcState === 'PROHIBITATION_MODAL';

  const decisionState = qcResult as QC_DECISION_STATE;

  const { needSecondQualityControl = false, qualityControlRound = 0 } =
    updatedQcDataRef.current || {};

  const isOnHold = () => decisionState === QC_DECISION_STATE.HOLD;

  const checkPointRecords = (checkPointList || []).map((checkPoint) => {
    const { id: curCheckpointId, isSkipped, defaultStatus } = checkPoint;
    const checkPointDefects = (defects || []).filter((item) => {
      return item.checkPointId === curCheckpointId;
    });

    const defectsIds = (checkPointDefects || []).map((defect) => defect.id);

    let status: CheckpointStatueType = defectsIds.length
      ? 'not_compliant'
      : 'compliant';

    const record = qcRecord?.checkpointRecords?.find(
      (r) => r.checkpoint.id === curCheckpointId
    );

    // If Record has data meaning this Product has been QC before, need to populate prev data
    // In this case need to look for Status in Record obj first cause it's the data that logged by an Agent last time
    const checkpointRecordStatus: CheckpointStatueType | undefined =
      record?.status;

    // User actually click check/uncheck Skip the checkpoint, otherwise isSkipped is undefined
    if (isSkipped !== undefined) {
      status = isSkipped ? 'not_applicable' : status;
    }
    // In case user don't action with Skip checkbox,
    // need to look into Status in Record obj, if null then use DefaultStatus
    else if (defaultStatus === 'not_applicable') {
      status = checkpointRecordStatus || defaultStatus;
    }

    return {
      checkpointId: curCheckpointId,
      status,
      defectRecordIds: defectsIds,
    };
  });

  const getQcSubmitParams = () => {
    const standbyReasonUuid = isOnHold()
      ? ((childRef.current as any)?.getValue()?.standbyReason
          ?.uuid as StandbyReasonType['uuid'])
      : null;

    const standbyComment = isOnHold()
      ? (childRef.current as any)?.getValue()?.comment
      : null;

    const { internal: internalPhotos, public: publicPhotos } = qcPhotos;

    const record = {
      additionalItemRecords: (additionalItems || []).map((item) => {
        return {
          additionalItemUuid: item.uuid,
          status: 'added' as AddItemStatusType,
        };
      }),
      checkpointRecords: checkPointRecords,
      agentComment: agentComment || null,
      agentPublicComment: agentPublicComment || null,
      weight: parseDecimal(weight || '0') || null,
      hallmarks: hallmarks || null,
      isRefurbished: refurbishment,
      isFunctioned: isWorkingWatch,
      internalAdditionalPhotos: internalPhotos.length
        ? internalPhotos.map((p) => p.imagePath)
        : null,
      publicAdditionalPhotos: publicPhotos.length
        ? publicPhotos.map((p) => p.imagePath)
        : null,
    };

    return {
      standbyReasonUuid,
      standbyComment,
      record,
    };
  };

  const validateFields = () => {
    let validateResult = true;

    if (isOnHold()) {
      if (!(childRef.current as any)?.validate()) {
        validateResult = false;
      }
    }

    return validateResult;
  };

  const closeModal = () =>
    setModalInfo({
      modalType: '',
      modalInfo: {},
    });

  const resetQcQueryData = () => {
    setProductId('');
    queryClient.removeQueries({ queryKey: [SCAN_PRODUCT] });
  };

  const toHomeScreen = () => {
    resetQcQueryData();
    closeModal();
    navigate(RoutesMapping.HOME, { replace: true });
  };

  const handleProhibited = () => {
    closeModal();
    setModalInfo({
      modalType: MODAL_TYPES.QC_SUBMIT,
      modalInfo: {
        qcResult: QC_DECISION_STATE.REJECT,
      },
    });
  };

  const onSubmitQcDecision = async () => {
    if (!validateFields()) {
      return null;
    }

    datadogRum.addAction('QC decision being submitted', {
      ...getQcSubmitParams(),
    });

    updateQcState(
      {
        uuid,
        state: decisionState,
        ...getQcSubmitParams(),
      },
      {
        onSuccess: ({ data }) => {
          datadogRum.addAction(`Submit QC successfully`, {
            resData: data,
          });

          if (!data) return;

          updatedQcDataRef.current = data;

          setSubmissionError(null);
          setQcSubmitted(true);

          if (isOnHold()) toHomeScreen();

          // Create success toast when redirect to Scanning page
          const need2ndQc =
            !isOnHold() &&
            data.qualityControlRound === 1 &&
            data.needSecondQualityControl;

          setToast(getToast(t).QC_SUBMITTED(data.stateName, need2ndQc));
        },
        onError: (error) => {
          datadogRum.addAction('QC decision submission error', error);
          console.error(error);

          const errorMsg =
            error.response?.data?.['hydra:description'] || t('QC_V2.STH_WRONG');

          setSubmissionError(errorMsg);
          datadogRum.addError(errorMsg);

          setQcSubmitted(true);

          const {
            response: { data: { referenceErrorCode, agentUsername } = {} } = {},
          } = error;

          const isTookOver = referenceErrorCode === PRODUCT_TOOK_OVER_ERROR;

          if (isTookOver) {
            setToast(
              getToast(t).ITEM_TAKEN_BY_OTHERS(agentUsername || 'N/A', uuid)
            );
            toHomeScreen();
          } else setChildDialogOpen(true);
        },
      }
    );
  };

  const isShow2ndQcNotice = () => {
    if (hasMultiQC && !isSubmitted && !isProhibited) {
      const firstQcState =
        QC_RESPONSE_STATE_TO_DECISION_STATE_MAPPING[firstQc!.state];
      return decisionState !== firstQcState;
    }
    return false;
  };

  const getQcModalContent = () => {
    if (!isOnHold()) {
      if (isSubmitted) {
        return (
          <GuidingContent
            type={decisionState}
            need2ndQC={!!needSecondQualityControl && qualityControlRound < 2}
          />
        );
      }
      if (isShow2ndQcNotice())
        return (
          <SecondQcNotice
            firstQcData={firstQc!}
            secondQcDecisionState={decisionState}
          />
        );
    }

    switch (decisionState) {
      case QC_DECISION_STATE.ACCEPT:
        return <OkModalContent />;
      case QC_DECISION_STATE.REJECT:
        return isProhibited ? <ProhibitionKoContent /> : <KoModalContent />;
      case QC_DECISION_STATE.HOLD:
        return <OnHoldModalContent ref={childRef} />;
      case QC_DECISION_STATE.NEGOTIATE:
        return <NegoModalContent />;
      default:
        return null;
    }
  };

  const getQcModalTitle = () => {
    if (isShow2ndQcNotice()) return t('QC_V2.MAIN.CONFIRM_2ND_NOTICE_TITLE');
    return isSubmitted
      ? t('QC_V2.MAIN.CONFIRM_NOTE')
      : t('QC_V2.MAIN.CONFIRMATION');
  };

  const modalContent = getQcModalContent();

  return (
    <>
      <QcModal
        open={!!(modalType && decisionState)}
        header={getQcModalTitle()}
        content={modalContent}
        maxWidth="xs"
        id={`${modalType}  ${decisionState}`}
        actionButtons={
          isSubmitted
            ? {
                confirm: {
                  text: t('QC_V2.MAIN.OK'),
                  onClick: toHomeScreen,
                },
              }
            : {
                cancel: {
                  text: t('QC_V2.MAIN.CANCEL'),
                  onClick: closeModal,
                },
                confirm: {
                  text: t('QC_V2.MAIN.CONFIRM'),
                  onClick: () => {
                    if (isProhibited) handleProhibited();
                    else onSubmitQcDecision();
                  },
                  isSubmitting,
                },
              }
        }
      />
      {isChildDialogOpen && (
        <ErrorModal
          actionButton={{
            text: t('QC_V2.MAIN.OK'),
            onClick: () => {
              datadogRum.addAction('Error modal OK button clicked');
              setChildDialogOpen(false);
              setSubmissionError(null);
            },
          }}
          errorContent={submissionError}
        />
      )}
    </>
  );
}
