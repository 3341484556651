import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useStandbyReasons } from '@/hooks/use-standbyReasons';
import { StandbyReasonType } from '@/types/StandbyReasons';

const OnHoldModalContent = forwardRef((_, ref) => {
  const { isLoadingStandbyReasons, standbyReasons } = useStandbyReasons();
  const [comment, setComment] = useState<string>('');
  const [value, setValue] = useState<StandbyReasonType | null>(null);
  const [inputVal, setInputVal] = useState<string>('');
  const [error, setError] = useState<{
    standbyReason: boolean;
    comment: boolean;
  }>({
    standbyReason: false,
    comment: false,
  });
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return { standbyReason: value, comment };
    },
    validate: () => {
      const hasStandbyReason = value && value.name ? true : false;
      setError({
        standbyReason: !hasStandbyReason,
        comment: !comment,
      });

      return hasStandbyReason && comment;
    },
  }));

  if (isLoadingStandbyReasons) {
    return <div>{t('QC_V2.COMMON.LOADING')}</div>;
  }

  return (
    <>
      <FormControl error={error.standbyReason} variant="standard">
        <Autocomplete
          disablePortal={false}
          id="standby-reason-select"
          options={standbyReasons}
          getOptionLabel={(option) => option.name || ''}
          sx={{ width: 380, padding: '1rem 0 0', mb: '1rem' }}
          value={value}
          inputValue={inputVal}
          onInputChange={(_event, newInputValue) => {
            setInputVal(newInputValue);
          }}
          onChange={(_event, newValue) => {
            setError({ ...error, standbyReason: false });
            setValue(newValue as StandbyReasonType);
          }}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <Box
                key={key}
                component="li"
                {...optionProps}
                dd-custom-action={`${t('QC_V2.MAIN.ONHOLED_REASON_LABEL')}: ${option.name || 'Unknown (Cannot get selected value)'}`}
              >
                {option.name}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('QC_V2.MAIN.ONHOLED_REASON_LABEL')}
              placeholder={t('QC_V2.MAIN.ONHOLED_REASON_PLACEHOLDER')}
              helperText={
                error.standbyReason && t('QC_V2.COMMON.FEILD_REQUIRED')
              }
              required
              error={error.standbyReason}
              FormHelperTextProps={{
                sx: {
                  marginLeft: 0,
                },
              }}
            />
          )}
        />
      </FormControl>
      <FormControl error={error.comment} variant="standard">
        <TextField
          sx={{ width: 380 }}
          label={t('QC_V2.MAIN.ONHOLED_COMMENT_LABEL')}
          placeholder={t('QC_V2.MAIN.ONHOLED_COMMENT_PLACEHOLDER')}
          helperText={error.comment && t('QC_V2.COMMON.FEILD_REQUIRED')}
          required
          onChange={(e) => {
            setComment(e.target.value);
            setError({ ...error, comment: false });
          }}
          error={error.comment}
          FormHelperTextProps={{
            sx: {
              marginLeft: 0,
            },
          }}
        />
      </FormControl>
    </>
  );
});

OnHoldModalContent.displayName = 'OnHoldModalContent';

export default OnHoldModalContent;
