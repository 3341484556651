import { datadogRum } from '@datadog/browser-rum';
import SearchIcon from '@mui/icons-material/Search';
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Stack,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SnackBarNotification from '@/components/Common/SnackBar/SnackBar';
import StartOnHoldItem from '@/components/Scanning/StartOnHoldItem';
import { useAuthContext } from '@/context/Authentication.context';
import { ModalProvider } from '@/context/Modal.context';
import { openQCView, toQCScreen } from '@/helpers/routing';
import {
  QC_DECISION_STATE,
  isControlledByCurrentAgent,
  isDoneQC,
  isPendingControl,
  isOnHold,
  isBeingControl,
} from '@/helpers/states';
import { useProduct } from '@/hooks/use-product';
import useScanToast from '@/hooks/use-scan-toast';
import useUpdateQcState from '@/hooks/use-update-qc-state';

export const InputSearch = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [productId, setProductId] = useState<string>('');

  const { user: userInfo } = useAuthContext();

  const {
    qcQueryData,
    isQcQueryFetching,
    isQcSubmitted,
    refetchQcQuery,
    setProductId: setProductIdContext,
    count,
    toast,
    setToast,
  } = useProduct();

  const { mutate: mutateQcState, isPending } = useUpdateQcState();

  const { getScanningErrorToast, getApiErrorToastInfo } = useScanToast();

  const inputRef = useRef<HTMLInputElement>(null);

  const latestQc = useMemo(() => {
    if (isQcQueryFetching) return undefined;
    return qcQueryData ? qcQueryData[0] : undefined;
  }, [qcQueryData, isQcQueryFetching]);

  const showTakeOnHold = !isQcSubmitted && latestQc && isOnHold(latestQc.state);

  const updateQcState = (uuid: string) => {
    mutateQcState(
      { uuid, state: QC_DECISION_STATE.CONTROL },
      {
        onSuccess: () => refetchQcQuery(),
        onError: (error) => {
          if (error?.response?.data) {
            setToast(getApiErrorToastInfo(error, productId));
          }
        },
      }
    );
  };

  useEffect(() => {
    if (isQcSubmitted || !latestQc) return;

    const { uuid, state, agent, product: { id = 0 } = {} } = latestQc;

    if (uuid && isPendingControl(state)) {
      updateQcState(uuid);
      return;
    }

    if (
      agent &&
      isBeingControl(state) &&
      isControlledByCurrentAgent(agent, userInfo)
    ) {
      toQCScreen(id, navigate);
      return;
    }

    if (isDoneQC(state)) {
      datadogRum.addAction(`View QC result ${id}`, { id });
      openQCView(id.toString());
      return;
    }

    const latestQcProductId = id ? id.toString() : undefined;
    const errorToast = getScanningErrorToast(latestQc, latestQcProductId);

    if (errorToast) setToast(errorToast);
    else setToast(undefined);
  }, [latestQc, isQcSubmitted]);

  useEffect(() => {
    // Clear input whenever toast shows up
    if (toast) {
      setProductId('');
      if (inputRef.current) inputRef.current.focus();
    }
  }, [toast]);

  const handleProductIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove all non-digit characters
    const numericValue = e.target.value.replace(/\D/g, '');
    setProductId(numericValue);
    if (toast) setToast(undefined);
  };

  const handleSearchProduct = async (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter') {
      if (!productId && toast) setToast(undefined);

      setProductIdContext(productId);
      datadogRum.addAction(`Scan product ${productId}`, { id: productId });
    }
  };

  const isLoading = isPending || isQcQueryFetching;

  return (
    <ModalProvider>
      <Box
        className="qc-search--container"
        sx={(theme) => ({
          background: theme.palette.background.default,
        })}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%', paddingBottom: '3rem' }}
        >
          {!!toast && (
            <SnackBarNotification key={`${productId}_${count}`} toast={toast} />
          )}

          <Typography variant="h5" gutterBottom sx={{ marginBottom: '1.5rem' }}>
            {t('QC_V2.SCAN.TITLE')}
          </Typography>

          <Stack>
            <TextField
              inputRef={inputRef}
              data-testid="product_id_input"
              autoFocus
              variant="outlined"
              placeholder={t('QC_V2.SCAN.INPUT_PLACEHOLDER')}
              sx={{ width: '350px', paddingLeft: 0 }}
              value={productId}
              onChange={handleProductIdChange}
              onKeyDown={handleSearchProduct}
              disabled={isLoading}
              InputProps={{
                sx: { paddingLeft: 0 },
                startAdornment: (
                  <InputAdornment position="end">
                    {isLoading ? (
                      <Stack p={1} pr={1.75}>
                        <CircularProgress size={18} />
                      </Stack>
                    ) : (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Grid>
      </Box>

      {showTakeOnHold && <StartOnHoldItem qcData={latestQc} />}
    </ModalProvider>
  );
};
