import { QueryObserverResult } from '@tanstack/react-query';
import { useState, createContext, FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getToast } from '@/helpers/toast';
import useQcQuery from '@/hooks/use-qc-query';
import useScanToast from '@/hooks/use-scan-toast';
import { CustomError } from '@/types/AxiosError';
import { QcInfoType } from '@/types/QcInfo';
import { ToastType } from '@/types/Toast';

export interface QcRoundData {
  isProhibited?: boolean;
}
export type QcRoundType = Record<number, QcRoundData>;

interface ProductContextType {
  productId: string;
  setProductId: (productId: string) => void;
  count: number;
  hasMultiQC: boolean;
  refetchQcQuery: () => Promise<QueryObserverResult<QcInfoType[], CustomError>>;
  qcQueryData?: QcInfoType[];
  qcQueryError?: CustomError | null;
  isQcQueryFetching?: boolean;
  isQcSubmitted: boolean;
  setQcSubmitted: (value: boolean) => void;
  toast?: ToastType;
  setToast: (toast?: ToastType) => void;
  qcRounds?: QcRoundType;
  setQcRounds: (round: number, data: QcRoundData) => void;
}

const initialState = {};

interface ProductProviderProps {
  children: ReactNode;
}

export const ProductContext = createContext<ProductContextType>(
  initialState as ProductContextType
);

export const ProductProvider: FC<ProductProviderProps> = ({ children }) => {
  const { t } = useTranslation();

  const [productId, setProductId] = useState<string>('');

  // Use this special count value to trigger rerender when search again with same productId
  // TODO: to remove this count state
  const [count, setCount] = useState<number>(0);

  const [isQcSubmitted, setQcSubmitted] = useState(false);

  const [qcRounds, setQcRounds] = useState<QcRoundType>();

  const { toast, setToast, getApiErrorToastInfo } = useScanToast();

  const {
    data: qcQueryData,
    refetch,
    error,
    isFetching,
  } = useQcQuery(productId);

  const hasMultiQC = !!qcQueryData && qcQueryData.length > 1;

  // Handle basic toast message from getting Scanned Data
  useEffect(() => {
    if (isFetching) {
      setToast(undefined);
      setQcSubmitted(false);
      return;
    }
    if (error) {
      setToast(getApiErrorToastInfo(error, productId));
      return;
    }
    if (qcQueryData && !qcQueryData.length) {
      setToast(getToast(t).NO_QC_FOUND(productId));
      return;
    }
  }, [qcQueryData, error, isFetching]);

  useEffect(() => {
    if (productId) refetch();
  }, [productId, count]);

  const updateProductId = (id: string) => {
    // A new request should be sent out when count is updated
    setCount(count + 1);
    setProductId(id.trim());
  };

  const updateQcRound = (round: number, data: QcRoundData) => {
    setQcRounds((prev) => ({ ...prev, [round]: { ...data } }));
  };

  const values = {
    productId,
    setProductId: updateProductId,
    count,
    hasMultiQC,
    refetchQcQuery: refetch,
    qcQueryData,
    qcQueryError: error,
    isQcQueryFetching: isFetching,
    isQcSubmitted,
    setQcSubmitted,
    toast,
    setToast,
    setQcRounds: updateQcRound,
    qcRounds,
  };

  return (
    <ProductContext.Provider value={values}>{children}</ProductContext.Provider>
  );
};
