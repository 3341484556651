import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { ChangeEvent, RefObject, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhotoErrors from '@/components/Common/Error/PhotoErrors';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { Env } from '@/environments';
import {
  buildImageUrl,
  FILE_MAX_SIZE,
  generateImgId,
  getPhotoInfo,
  MAX_UPLOAD_FILE,
  SIZE_IN_MB,
} from '@/helpers/image';
import { useUploadImage } from '@/hooks/defect-details/use-upload-image';
import { PHOTO_TYPE } from '@/types/interfaces/defect-details';
import { IHydraError } from '@/types/interfaces/hydra';
import { SelectedPhotoType } from './PhotoForm';
import PhotoItem from './PhotoItem';
import QRCode from './QRCode';
import TakingPicture from './TakingPicture';
import Button from '../Button';
import { DynamicFormValues } from '../DefectDetails';
import LoadingBox from '../LoadingBox';
import UploadButton from '../UploadButton';

interface UploadedPhotoFormProps {
  videoRef: RefObject<HTMLVideoElement>;
  turnOffCamera: () => void;
  setStream: (stream: MediaStream) => void;
  onClick: (data?: SelectedPhotoType) => void;
  selectedPhoto?: SelectedPhotoType;
}

const UploadedPhotoForm = ({
  selectedPhoto,
  videoRef,
  turnOffCamera,
  setStream,
  onClick,
}: UploadedPhotoFormProps) => {
  const { t } = useTranslation();

  const { qcData: { uuid = '' } = {} } = useQualityControlTool();

  const { control } = useFormContext<DynamicFormValues>();

  const {
    fields: uploadedPhotos,
    append,
    update,
  } = useFieldArray({
    control,
    name: 'uploadedPhotos',
  });

  const { isPending, mutateAsync } = useUploadImage('defect');

  const [openTakePhoto, setOpenTakePhoto] = useState(false);

  const [openTakePhotoTest, setOpenTakePhotoTest] = useState(false);

  const [errors, setErrors] = useState<Array<string>>([]);

  const isDisabled = useMemo(() => {
    const shownPhotos = uploadedPhotos.filter((p) => !p.isHidden);
    return shownPhotos.length === MAX_UPLOAD_FILE;
  }, [uploadedPhotos]);

  const handleCheck = (id: number, imgName: string) => (checked: boolean) => {
    const photo = {
      id,
      checked,
      path: `${PHOTO_TYPE.NPV}/${imgName}`,
      type: PHOTO_TYPE.NPV,
    };

    let inputtedField = uploadedPhotos[id];

    if (!inputtedField) {
      update(photo.id, photo);
      return;
    }

    inputtedField = Object.assign(inputtedField, photo);
    update(photo.id, inputtedField);
  };

  const clearPhotoFromView = () => onClick(undefined);

  const handleOpenTakingPhoto = () => {
    const value = !openTakePhoto;

    if (!value) turnOffCamera();
    setOpenTakePhoto(value);

    clearPhotoFromView();
  };

  const handleClick = (data: SelectedPhotoType) => () => {
    if (openTakePhoto) handleOpenTakingPhoto();
    onClick(data);
  };

  const handleRemove = (index: number, isClicked: boolean) => () => {
    if (!uploadedPhotos.length || !uploadedPhotos?.[index]) return;

    const inputtedField = uploadedPhotos[index];
    update(index, { ...inputtedField, id: index, isHidden: true });

    if (!isClicked) return;

    clearPhotoFromView();
  };

  const upload = async (files: File[]) => {
    const errors: string[] = [];

    const requests = files.map((f) =>
      mutateAsync({
        uuid,
        file: f,
      })
    );

    const result = await Promise.allSettled(requests);

    result.forEach((r, idx) => {
      if (r.status === 'fulfilled') {
        const id = !uploadedPhotos.length ? idx : uploadedPhotos.length + idx;
        append({ id, path: r.value.imageUrl });
      } else {
        const { response: { data: errorData } = { data: null } } =
          (r.reason as AxiosError<IHydraError>) || {};

        if (errorData) errors.push(errorData['hydra:description']);
      }
    });

    return { errors };
  };

  const handleUploadFiles = async (e?: ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) return;

    const chosenFiles = e?.target.files;

    if (!chosenFiles) return;

    let allErrors: string[] = [];

    const uploadingFiles = [...chosenFiles];

    const hasLargeFile = uploadingFiles.some((f) => f.size > FILE_MAX_SIZE);

    if (hasLargeFile) {
      allErrors.push(
        t('QC_V2.DEFECT.MAX_UPLOAD_SIZE', { size: `${SIZE_IN_MB} MB` })
      );
    }

    const uploaded = uploadedPhotos.filter((p) => !p.isHidden);

    const uploadedLength = MAX_UPLOAD_FILE - uploaded.length;

    if (uploadedLength <= 0) return;

    const files = uploadingFiles
      .slice(0, uploadedLength)
      .filter((f) => f.size <= FILE_MAX_SIZE)
      .map(
        (f) =>
          new File([f], `${Date.now()}-${(f as File).name}`, {
            type: f.type,
          })
      );

    if (!files.length) {
      if (allErrors.length) setErrors(allErrors);
      return;
    }

    const { errors: responseErrors } = await upload(files);

    if (responseErrors.length) allErrors.push(...responseErrors);

    allErrors = allErrors.filter(function (item, pos) {
      return allErrors.indexOf(item) == pos;
    });

    setErrors(allErrors);

    e.target.value = '';
  };

  const handleUploadBlob = (blob?: Blob) => {
    if (isDisabled) return;

    const chosenFile: Blob | undefined = blob;

    if (!chosenFile) return;

    const fileName = `${Date.now()}`;

    const file = new File([chosenFile], fileName, {
      type: chosenFile.type,
    });

    upload([file]);
  };

  const showQRBtn = (process.env.NODE_ENV as Env) === 'development';

  return (
    <>
      <Typography variant="body1" sx={{ mt: 2 }}>
        {t('QC_V2.DEFECT.UPLOADED_PHOTOS')?.toUpperCase()}
      </Typography>

      <Typography variant="body2" fontStyle="italic" sx={{ mb: 2 }}>
        {`(${t('QC_V2.DEFECT.MAX_UPLOAD_PHOTOS', { number: MAX_UPLOAD_FILE })})`}
      </Typography>

      <Stack
        sx={{ mt: 2, flexDirection: 'row', gap: 2, flexWrap: 'wrap', mb: 1 }}
      >
        <UploadButton
          name="upload-defect-photos"
          onUpload={handleUploadFiles}
          disabled={isDisabled || isPending}
        />

        <Button
          name={t('QC_V2.DEFECT.TAKE_A_PHOTO')}
          icon={<CameraAltOutlinedIcon />}
          isActive={openTakePhoto}
          props={{
            onClick: handleOpenTakingPhoto,
          }}
        />

        {showQRBtn && (
          <Button
            name="Test QR"
            icon={<CameraAltOutlinedIcon />}
            isActive={openTakePhotoTest}
            props={{
              onClick: () => setOpenTakePhotoTest(!openTakePhotoTest),
            }}
          />
        )}

        {uploadedPhotos.map((field, idx) => {
          if (!field.path || field.isHidden) return null;

          const { imgType, imgName } = getPhotoInfo(field.path);

          const url = buildImageUrl({
            path: imgName,
            type: imgType as PHOTO_TYPE,
          });

          const htmlId = generateImgId(PHOTO_TYPE.NPV, idx);

          const isClicked = htmlId === selectedPhoto?.htmlId;

          return (
            <PhotoItem
              key={field.id}
              imgUrl={url}
              name={`uploadedPhotos.${idx}`}
              isChecked={field.checked}
              isClicked={isClicked}
              onClick={handleClick({
                htmlId,
                type: PHOTO_TYPE.NPV,
                index: idx,
                path: `${PHOTO_TYPE.NPV}/${imgName}`,
              })}
              onDelete={handleRemove(idx, isClicked)}
              onChange={handleCheck(idx, imgName)}
            />
          );
        })}

        {isPending && <LoadingBox />}
      </Stack>

      <PhotoErrors errors={errors} />

      <TakingPicture
        onUpload={handleUploadBlob}
        setStream={setStream}
        videoRef={videoRef}
        openTakePhoto={openTakePhoto}
        disabled={isDisabled}
      />

      {openTakePhotoTest && <QRCode />}
    </>
  );
};

export default UploadedPhotoForm;
