import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQcStates } from '@/hooks/use-qcStates';
import { QcStatesType } from '@/types/QcStatus';

const QcStatusSelect = forwardRef((_props, ref) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<QcStatesType[]>([]);
  const [, setInputVal] = useState<string>('');
  const { qcStates } = useQcStates();

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return { qcStatus: value };
    },
  }));

  return (
    <Autocomplete
      multiple
      fullWidth
      disablePortal
      id="qc-status-select"
      options={qcStates || []}
      value={value}
      getOptionLabel={(option) => option.name || ''}
      onInputChange={(_event, newInputValue) => {
        setInputVal(newInputValue);
      }}
      onChange={(_event, newValue) => {
        setValue(newValue as QcStatesType[]);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('QC_V2.QC_LST.QC_STATUS')}
          placeholder={t('QC_V2.QC_LST.STATUS_PLACEHOLDER')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            {...optionProps}
            dd-custom-action={`${t('QC_V2.QC_LST.QC_STATUS')}: ${option.name || 'Unknown (Cannot get selected value)'}`}
          >
            {option.name}
          </Box>
        );
      }}
    />
  );
});

QcStatusSelect.displayName = 'QcStatusSelect';

export default QcStatusSelect;
