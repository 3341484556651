import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '@/context/Authentication.context';
import {
  BEING_CONTROL_AN_ITEM_ERROR,
  isBeingControl,
  isControlledByCurrentAgent,
  isExitQC,
  PRODUCT_RELISTED_ERROR,
} from '@/helpers/states';
import { getToast } from '@/helpers/toast';
import { CustomError } from '@/types/AxiosError';
import { QcInfoType } from '@/types/QcInfo';
import { ToastType } from '@/types/Toast';

const useScanToast = () => {
  const { t } = useTranslation();

  const { user: userInfo } = useAuthContext();

  const [toast, setToast] = useState<ToastType>();

  const getScanningErrorToast = (
    qcData: QcInfoType,
    productId?: string
  ): ToastType | undefined => {
    const { state, stateName, agent, uuid } = qcData;

    if (
      agent &&
      isBeingControl(state) &&
      !isControlledByCurrentAgent(agent, userInfo)
    ) {
      return getToast(t)['ITEM_TAKEN_BY_OTHERS'](agent.email, uuid);
    }

    if (isExitQC(state)) {
      return getToast(t)['QC_ALREADY_FINISHED'](stateName, productId || '');
    }
  };

  const getApiErrorToastInfo = (
    error: CustomError,
    productId?: string
  ): ToastType | undefined => {
    const {
      response: {
        data: {
          referenceErrorCode,
          productId: beingControlProductId,
          needAuthentication,
        } = {},
      } = {},
    } = error;

    const apiMessage =
      error?.response?.data?.['hydra:description'] || t('QC_V2.STH_WRONG');

    if (referenceErrorCode) {
      switch (referenceErrorCode) {
        case BEING_CONTROL_AN_ITEM_ERROR:
          return getToast(t)['BEING_CONTROL_AN_ITEM'](
            (beingControlProductId || 'N/A').toString()
          );
        case PRODUCT_RELISTED_ERROR:
          return getToast(t)['PRODUCT_RELISTED_ERROR']();
        default:
          break;
      }
    }

    if (needAuthentication) {
      return getToast(t)['NEED_AUTHENTICATION'](productId || '');
    }

    if (apiMessage) return getToast(t)['SERVER_ERROR'](apiMessage);

    return undefined;
  };

  return {
    toast,
    setToast,
    getApiErrorToastInfo,
    getScanningErrorToast,
  };
};

export default useScanToast;
