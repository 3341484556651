import { useQuery } from '@tanstack/react-query';
import { GET_BRANDS } from '@/helpers/query-keys';
import { getBrands } from '@/services/qc.service';

const PAGE_SIZE = 200;

export const useGetBrands = (searchText: string) => {
  return useQuery({
    queryKey: [GET_BRANDS, searchText],
    queryFn: async () => getBrands(PAGE_SIZE, searchText),
    staleTime: Infinity,
    retry: false,
  });
};
