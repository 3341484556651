import { Stack, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QC_DECISION_STATE } from '@/helpers/states';
import { useProduct } from '@/hooks/use-product';
import useUpdateQcState from '@/hooks/use-update-qc-state';

interface TakeOverToastContentProps {
  uuid: string;
  agentEmail: string;
}

const TakeOverToastContent = ({
  uuid,
  agentEmail,
}: TakeOverToastContentProps) => {
  const { t } = useTranslation();

  const { refetchQcQuery, productId } = useProduct();

  const { mutate, isPending, error } = useUpdateQcState();

  const takeControl = ({ uuid }: { uuid: string }) => {
    mutate(
      { uuid, state: QC_DECISION_STATE.TAKE_OVER_CONTROL },
      {
        onSuccess: () => refetchQcQuery(),
      }
    );
  };

  return (
    <Stack gap={0.5}>
      <Typography variant="body2">
        {t('QC_V2.SCAN.MSG_PRODUCT_TAKEN', {
          productId,
          agentEmail: agentEmail || 'N/A',
        })}
      </Typography>

      {isPending ? (
        <CircularProgress size={18} />
      ) : (
        <Typography
          variant="body2"
          sx={{
            textDecoration: 'underline',
            ':hover': { cursor: 'pointer' },
          }}
          onClick={() => {
            if (uuid) takeControl({ uuid });
          }}
        >
          {t('QC_V2.SCAN.TAKE_OVER')}
        </Typography>
      )}

      {!!error && (
        <Typography variant="body2" color={(theme) => theme.palette.error.main}>
          {error.response.data['hydra:description'] || t('QC_V2.STH_WRONG')}
        </Typography>
      )}
    </Stack>
  );
};

export default TakeOverToastContent;
