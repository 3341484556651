import { Grid } from '@mui/material';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { checkRequired } from '@/helpers/defect-form';
import { SelectOptions } from '@/types/interfaces/form';
import FormAutocomplete from '../../Common/Form/FormAutocomplete';
import { DynamicFormValues } from '../DefectDetails';

interface DefectImportanceProps {
  control: Control<DynamicFormValues>;
  selectOptions: SelectOptions;
  disabled: boolean;
  isRequired?: boolean;
}

const DefectImportanceForm = ({
  control,
  selectOptions,
  disabled,
  isRequired,
}: DefectImportanceProps) => {
  const { t } = useTranslation();

  const required = isRequired ? t('QC_V2.DEFECT.REQUIRED') : undefined;

  const isImportanceHidden = !selectOptions.defectImportances.length;

  const isQuantityHidden = !selectOptions.defectQuantities.length;

  const isSizeHidden = !selectOptions.defectSizes.length;

  if (isImportanceHidden && isQuantityHidden && isSizeHidden) return null;

  return (
    <Grid container spacing={2.5} item>
      {!isImportanceHidden && (
        <Grid item xs={4}>
          <FormAutocomplete
            data-testid="defect_importance_combobox"
            control={control}
            name="defectImportance"
            label={t('QC_V2.DEFECT.DEFECT_IMPORTANCE')}
            rules={{
              required: checkRequired(
                required,
                selectOptions.defectImportances
              ),
            }}
            autocompleteProps={{
              options: selectOptions.defectImportances,
              disabled: disabled,
              isOptionEqualToValue: (option, value) =>
                option.value === value.value,
            }}
          />
        </Grid>
      )}

      {!isQuantityHidden && (
        <Grid item xs={4}>
          <FormAutocomplete
            data-testid="defect_quantity_combobox"
            control={control}
            name="defectQuantity"
            label={t('QC_V2.DEFECT.DEFECT_QUANTITY')}
            rules={{
              required: checkRequired(required, selectOptions.defectQuantities),
            }}
            autocompleteProps={{
              options: selectOptions.defectQuantities,
              disabled: disabled,
              isOptionEqualToValue: (option, value) =>
                option.value === value.value,
            }}
          />
        </Grid>
      )}

      {!isSizeHidden && (
        <Grid item xs={4}>
          <FormAutocomplete
            data-testid="defect_size_combobox"
            control={control}
            name="defectSize"
            label={t('QC_V2.DEFECT.DEFECT_SIZE')}
            rules={{
              required: checkRequired(required, selectOptions.defectSizes),
            }}
            autocompleteProps={{
              options: selectOptions.defectSizes,
              disabled: disabled,
              isOptionEqualToValue: (option, value) =>
                option.value === value.value,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DefectImportanceForm;
