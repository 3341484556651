export enum SCAN_ALERT_TYPE {
  NO_QC_FOUND = 'NO_QC_FOUND',
  NEED_AUTHENTICATION = 'NEED_AUTHENTICATION',
  QC_SUBMITTED = 'QC_SUBMITTED',
  ITEM_TAKEN_BY_OTHERS = 'ITEM_TAKEN_BY_OTHERS',
  QC_ALREADY_FINISHED = 'QC_ALREADY_FINISHED',
  BEING_CONTROL_AN_ITEM = 'BEING_CONTROL_AN_ITEM',
  PRODUCT_RELISTED_ERROR = 'PRODUCT_RELISTED_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
}

export type ToastType = {
  title: string;
  icon: JSX.Element;
  message: string | JSX.Element;
  color: string;
  testid?: string;
};
