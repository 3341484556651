import { Trans } from 'react-i18next';
import { Theme } from '../Theme';

export default function KoModalContent() {
  return (
    <Trans i18nKey="QC_V2.MAIN.CONFIRM_ITEM_NOT_COMPLIANT">
      The item is
      <strong key="confirm_ko" style={{ color: Theme.palette.error.main }}>
        Not compliant
      </strong>
      !
    </Trans>
  );
}
