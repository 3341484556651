import { Grid } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Control, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { checkRequired } from '@/helpers/defect-form';
import { useGetDefects } from '@/hooks/defect-details/use-get-defects';
import { FormSelectOption, SelectOptions } from '@/types/interfaces/form';
import FormAutocomplete from '../../Common/Form/FormAutocomplete';
import { DynamicFormValues } from '../DefectDetails';

interface SizeFormProps {
  control: Control<DynamicFormValues>;
  selectOptions: SelectOptions;
  disabled: boolean;
  isRequired?: boolean;
}

const CategoryForm = ({
  control,
  selectOptions,
  disabled,
  isRequired,
}: SizeFormProps) => {
  const { t } = useTranslation();

  const { qcData: { uuid = '' } = {} } = useQualityControlTool();

  const { data: defects } = useGetDefects(uuid);

  const { getFieldState, setValue } = useFormContext<DynamicFormValues>();

  const { field: categoryField } = useController({
    name: 'category',
    control,
  });

  const selectedCategory = categoryField.value;

  const categoryState = getFieldState('category');

  const resetRelatedFields = () => {
    setValue('subcategory', null);
    setValue('sizeType', null);
    setValue('size', null);
  };

  useEffect(() => {
    if (categoryState.isDirty) resetRelatedFields();
  }, [categoryState.isDirty, selectedCategory?.value]);

  const subCategoryOptions: FormSelectOption[] = useMemo(() => {
    if (!selectedCategory?.legacyId) return [];

    const subCategories = defects?.subcategories?.filter(
      (s) => s.categoryLegacyId.toString() === selectedCategory.legacyId
    );

    if (!subCategories) return [];

    return subCategories
      .map((v) => ({
        label: v.name,
        value: v.id.toString(),
      }))
      .sort((a, b) => -b.label.localeCompare(a.label));
  }, [selectedCategory?.legacyId, defects]);

  const required = isRequired ? t('QC_V2.DEFECT.REQUIRED') : undefined;

  return (
    <Grid container spacing={2.5} item>
      <Grid item xs={6}>
        <FormAutocomplete
          data-testid="category_combobox"
          control={control}
          name="category"
          label={t('QC_V2.DEFECT.CATEGORY')}
          rules={{
            required: checkRequired(required, selectOptions.categories),
          }}
          autocompleteProps={{
            options: selectOptions.categories,
            disabled: disabled,
            isOptionEqualToValue: (option, value) =>
              option.value === value.value,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <FormAutocomplete
          data-testid="subcategory_combobox"
          control={control}
          name="subcategory"
          label={t('QC_V2.DEFECT.SUBCATEGORY')}
          rules={{
            required: checkRequired(required, subCategoryOptions),
          }}
          autocompleteProps={{
            options: subCategoryOptions,
            disabled: disabled,
            isOptionEqualToValue: (option, value) =>
              option.value === value.value,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CategoryForm;
