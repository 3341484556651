import { useRef } from 'react';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import ProductImages from './ProductImages';
import { ProductInfo } from './ProductInfo';
import './ProductInfo.scss';

export const GeneralInfo = () => {
  const { qcData: { product } = {} } = useQualityControlTool();

  const containerRef = useRef<HTMLDivElement>(null);

  if (!product || !product.id) return null;

  return (
    <div ref={containerRef}>
      <ProductImages images={product.images} containerRef={containerRef} />
      <ProductInfo />
    </div>
  );
};
