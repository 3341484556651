import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { QC_DECISION_STATE } from '@/helpers/states';

interface GuidingContentProps {
  type: QC_DECISION_STATE;
  need2ndQC?: boolean;
}

const GuidingContent = ({ type, need2ndQC }: GuidingContentProps) => {
  const { t } = useTranslation();

  const typeLabel = useMemo(() => {
    if (need2ndQC) return t('QC_V2.MAIN.CONFIRM_2ND_QC');

    switch (type) {
      case QC_DECISION_STATE.ACCEPT:
      case QC_DECISION_STATE.REJECT:
        return t('QC_V2.MAIN.CONFIRM_SEND_OUT');
      case QC_DECISION_STATE.NEGOTIATE:
        return t('QC_V2.MAIN.QC_NEGO');
      default:
        return 'N/A';
    }
  }, [type, need2ndQC]);

  return (
    <>
      {need2ndQC && (
        <Trans i18nKey="QC_V2.MAIN.CONFIRM_2ND_QC_DES">
          The item required <strong>2nd QC</strong>
        </Trans>
      )}
      <Typography variant="body1">{t('QC_V2.MAIN.CONFIRM_SORT_TO')}</Typography>
      <Typography variant="h3">{typeLabel.toUpperCase()}</Typography>
    </>
  );
};

export default GuidingContent;
