import { FormSelectOption } from './form';

export type DefectType = {
  id: number;
  name: string;
};

export enum REQUIRED_FIELD {
  PICTURE = 'picture',
  IMPORTANCE = 'importance',
  COLOR = 'color',
  MATERIAL = 'material',
  SIZE = 'size',
  WEIGHT = 'weight',
  DIMENSION = 'dimension',
  LOCATION = 'location',
  MODEL = 'model',
  CATEGORY = 'category',
  BRAND = 'brand',
}

export interface Bucket {
  id: number;
  name: string;
  requiredFields: REQUIRED_FIELD[];
  optionalFields?: REQUIRED_FIELD[];
  defectTypes: DefectType[];
}

export type CommonDefectType = {
  id: number;
  name: string;
};
export type Material = {
  id: number;
  name: string;
  legacyId: number;
  uuid: string;
};
export type SizeType = {
  type: string;
  values: string[];
};
export type CategorySizeType = {
  categoryId: number;
  sizes: SizeType[];
};
export type CategoryType = {
  id: number;
  legacyId: number;
  name: string;
  completeName: string;
  universeLegacyId: number;
};
export type SubcategoryType = {
  id: number;
  legacyId: number;
  name: string;
  categoryLegacyId: number;
};
export type DefectImportance = {
  label: string;
  value: string;
};
export type Photo = {
  path: string;
  type: string;
  annotations: { x: number; y: number }[];
};
export enum PHOTO_TYPE {
  PRODUIT = 'produit',
  NPV = 'npv',
}

export type FormDimension = ({ id: number; value: string } | undefined)[];

export interface Defect {
  colors: CommonDefectType[];
  categoryBrandModels: CommonDefectType[];
  defectImportances: DefectImportance[];
  defectQuantities: DefectImportance[];
  defectSizes: DefectImportance[];
  materials: Material[];
  sizeTypes: SizeType[];
  categories: CategoryType[];
  subcategories: SubcategoryType[];
  categorySizeTypes: CategorySizeType[];
  brand?: string;
}

type TypeDimension = {
  id: number;
  name: string;
};

export interface Dimension {
  id: number;
  name: string;
  typeDimension?: TypeDimension;
}

export interface BucketOptionType extends Partial<Bucket>, FormSelectOption {}

export interface DefectOptionType
  extends Partial<DefectType>,
    FormSelectOption {
  bucketId: number;
  bucketName: string;
  requiredFields?: REQUIRED_FIELD[];
  optionalFields?: REQUIRED_FIELD[];
}

export type DimensionRequest = {
  id: number;
  value: string;
  valueInch?: string;
  typeDimension?: TypeDimension;
}[];

export interface DefectRequest {
  defectTypeId: number;
  photos?: {
    path: string;
    type: 'original' | 'uploaded';
    annotations: { x: number; y: number }[];
  }[];
  internalComment?: string;
  defectImportance?: string;
  defectQuantity?: string;
  defectSize?: string;
  category?: number;
  subcategory?: number;
  size?: string;
  sizeType?: string;
  weight?: number;
  color?: number;
  categoryBrandModel?: number;
  material1?: number;
  material1Percent?: number;
  material2?: number;
  material2Percent?: number;
  dimensions?: DimensionRequest;
  isAppearedInPhoto?: boolean;
  brand?: string;
}

export interface SubmittedDefect
  extends Omit<
    DefectRequest,
    | 'defectTypeId'
    | 'color'
    | 'categoryBrandModel'
    | 'material1'
    | 'material2'
    | 'defectImportance'
    | 'defectQuantity'
    | 'defectSize'
    | 'category'
    | 'subcategory'
  > {
  id: number;
  defectType?: DefectType & { bucket: Bucket };
  color?: CommonDefectType;
  categoryBrandModel?: CommonDefectType;
  material1?: Material;
  material2?: Material;
  defectImportance?: DefectImportance;
  defectQuantity?: DefectImportance;
  defectSize?: DefectImportance;
  actualValue?: string;
  isProhibited?: boolean;
  category?: CategoryType;
  subcategory?: SubcategoryType;
}

export type SortDynamicFieldType = Array<{
  type: REQUIRED_FIELD;
  element: JSX.Element;
}>;
