import { Button, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { isPending2ndRound } from '@/helpers/states';
import { useProduct } from '@/hooks/use-product';
import { QcInfoType } from '@/types/QcInfo';
import PanelHeaderItem from './PanelHeaderItem';

export type ErrorType = {
  field: 'weight' | 'hallmarks' | 'watch';
  message: string;
};

interface PanelHeaderProps {
  selectQcRound: (round: number) => void;
}

const PanelHeader = ({ selectQcRound }: PanelHeaderProps) => {
  const { qcQueryData, hasMultiQC, qcRounds, setQcRounds } = useProduct();

  const { qcData: { qualityControlRound } = {}, defects } =
    useQualityControlTool();

  const isProhibited = !!defects.find((defect) => defect.isProhibited);

  useEffect(() => {
    if (!qualityControlRound) return;
    setQcRounds(qualityControlRound, { isProhibited });
  }, [isProhibited, qualityControlRound]);

  if (!qcQueryData || !qcQueryData.length) return null;

  let qcList: QcInfoType[] = qcQueryData;

  if (isPending2ndRound(hasMultiQC, qcQueryData[0].state)) {
    // Only show previous QC record, cause latest one hadn't been scanned
    qcList = [qcQueryData[qcQueryData.length - 1]];
  }

  return (
    <Stack
      flexDirection="row-reverse"
      justifyContent="start"
      alignItems="center"
    >
      {qcList.map((d) => {
        if (!d.qualityControlRound) return null;

        const isQcRoundMatched = qualityControlRound === d.qualityControlRound;
        const isSelected = qcList.length === 1 || isQcRoundMatched;

        const isProhibited = qcRounds?.[d.qualityControlRound]?.isProhibited;

        return (
          <Button
            key={`${d.uuid}-${d.qualityControlRound}`}
            sx={{
              p: '1rem',
              borderRadius: 0,
              borderBottom: isSelected ? `2px solid` : 'unset',
            }}
            onClick={() => {
              if (d.qualityControlRound != null && hasMultiQC) {
                selectQcRound(d.qualityControlRound);
              }
            }}
          >
            <PanelHeaderItem
              state={d.state}
              isProhibited={isProhibited}
              is2ndQc={d.qualityControlRound === 2}
            />
          </Button>
        );
      })}
    </Stack>
  );
};

export default PanelHeader;
