import { Alert, AlertTitle } from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { useState } from 'react';
import { ToastType } from '@/types/Toast';

type SnackBarPropsType = {
  toast: ToastType;
};

export default function SnackBarNotification({ toast }: SnackBarPropsType) {
  const [isOpen, setIsOpen] = useState<boolean>(!!toast);

  const { icon, color, title, message, testid } = toast;

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box>
      <Snackbar
        open={isOpen}
        sx={{ marginTop: '5rem', maxWidth: '500px' }}
        autoHideDuration={60000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert
          icon={icon}
          // @ts-expect-error: color type needs to be updated
          color={color}
          data-testid={testid}
        >
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
