import { createFilterOptions, Grid } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormFreeSolo from '@/components/Common/Form/FormFreeSolo';
import { checkRequired } from '@/helpers/defect-form';
import { useGetBrands } from '@/hooks/defect-details/use-get-brands';
import useDebounced from '@/hooks/use-debounce';
import { DynamicFormValues } from '../DefectDetails';

interface BrandFormProps {
  control: Control<DynamicFormValues>;
  disabled: boolean;
  isRequired?: boolean;
}

const filter = createFilterOptions<string>();

const BrandForm = ({ control, disabled, isRequired }: BrandFormProps) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');

  const { data: brands, isLoading } = useGetBrands(searchText);

  const debouncedSearchChange = useDebounced(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
    }
  );

  const options = brands || [];

  const required = isRequired ? t('QC_V2.DEFECT.REQUIRED') : undefined;

  return (
    <Grid container spacing={2.5} item>
      <Grid item xs={12}>
        <FormFreeSolo
          data-testid="brand_combobox"
          control={control}
          name="brand"
          label={t('QC_V2.DEFECT.BRAND')}
          rules={{
            required: checkRequired(required, options),
          }}
          textFieldProps={{
            onChange: debouncedSearchChange,
          }}
          autocompleteProps={{
            freeSolo: true,
            clearOnBlur: true,
            disableClearable: true,
            options: options,
            disabled: disabled,
            isOptionEqualToValue: (option, value) => option === value,
            filterOptions: (options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue === '') return filtered;

              if (isLoading) {
                filtered.unshift(params.inputValue, t('QC_V2.COMMON.LOADING'));
              } else {
                filtered.unshift(params.inputValue);
              }

              return filtered;
            },
            onKeyDown: (ev) => {
              if (ev.key === 'Enter') ev.preventDefault();
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default BrandForm;
