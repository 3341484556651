import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { getPhotoErrors, MAX_UPLOAD_FILE } from '@/helpers/image';
import useUploadAdditionalPhotos, {
  UploadedFileType,
} from '@/hooks/defect-details/use-upload-additional-photos';
import { useUploadImage } from '@/hooks/defect-details/use-upload-image';
import { useQcMode } from '@/hooks/use-qcMode';
import { UploadImageType } from '@/services/qc.service';
import { IHydraError } from '@/types/interfaces/hydra';
import AdditionalInfoSection from './AdditionalInfoSection';
import PhotoErrors from '../Common/Error/PhotoErrors';
import LoadingBox from '../DefectDetailModal/LoadingBox';
import PhotoItem from '../DefectDetailModal/PhotoForms/PhotoItem';
import UploadButton from '../DefectDetailModal/UploadButton';
import PhotoView from '../Modal/PhotoView';

interface AdditionalPhotoProps {
  title: string;
  type: UploadImageType;
}

const AdditionalPhotos = ({ title, type }: AdditionalPhotoProps) => {
  const { updateQCPhotos, qcPhotos } = useQualityControlTool();

  const { isViewMode } = useQcMode();

  const { photos, getUploadingFiles, deleteFile, updatePhotos } =
    useUploadAdditionalPhotos();

  const { qcData: { uuid = '' } = {} } = useQualityControlTool();

  const { mutateAsync, isPending } = useUploadImage(type);

  const [isLoading, setLoading] = useState(false);

  const [errors, setErrors] = useState<Array<string>>([]);

  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const [isShowPhotoView, setShowPhotoView] = useState(false);

  useEffect(() => {
    // In case ON-HOLD, re-populate photos from last QC
    const hasRecordPhotos =
      !photos.length && (qcPhotos.internal.length || qcPhotos.public.length);

    if (isViewMode || hasRecordPhotos) {
      const viewedPhotos =
        type === 'internal' ? qcPhotos.internal : qcPhotos.public;
      updatePhotos(viewedPhotos);
    }
  }, [isViewMode, qcPhotos]);

  const photoGalleries = useMemo(() => {
    if (!photos.length) return [];
    return photos.map((p) => p.imageUrl);
  }, [photos]);

  const handleUploadFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files: chosenFiles, errors } = getUploadingFiles(e.target.files);

    if (!chosenFiles.length) {
      if (errors.length) setErrors(errors);
      return;
    }

    let allErrors: string[] = errors;

    const requests = chosenFiles.map((f) => mutateAsync({ uuid, file: f }));

    setLoading(true);

    const result = await Promise.allSettled(requests);

    const uploadedPhotos = result
      .map((r, idx) => {
        if (r.status === 'fulfilled') {
          return {
            id: `${idx}-${Date.now()}`,
            imageUrl: r.value.imageUrl,
            imagePath: r.value.imagePath,
          };
        }

        const { response: { data: errorData } = { data: null } } =
          (r.reason as AxiosError<IHydraError>) || {};

        allErrors = getPhotoErrors(allErrors, errorData);
        return undefined;
      })
      .filter((p) => !!p) as UploadedFileType[];

    setErrors(allErrors);

    updatePhotos(uploadedPhotos);

    updateQCPhotos([...photos, ...uploadedPhotos], type);

    setLoading(false);

    e.target.value = '';
  };

  const handleDeleteFile = (id: string) => {
    const remainPhotos = deleteFile(id);
    updateQCPhotos(remainPhotos, type);
  };

  const handleShowPhotoView = (index: number) => {
    setSelectedPhotoIndex(index);
    setShowPhotoView(true);
  };

  const isDisabled =
    photos.length === MAX_UPLOAD_FILE || isViewMode || isPending;

  return (
    <>
      <Box ml="-1rem">
        <AdditionalInfoSection
          title={
            <Stack gap={1}>
              <Stack flexDirection="row" gap={0.5} alignItems="center">
                <Typography variant="overline">{title}</Typography>
              </Stack>

              <Stack flexDirection="row" mt={-2} ml={-1}>
                <UploadButton
                  name={`upload-${type}-photos`}
                  disabled={isDisabled}
                  onUpload={handleUploadFiles}
                  iconButton={
                    <IconButton
                      sx={{ color: 'text.default' }}
                      component="span"
                      disabled={isDisabled}
                    >
                      <AddPhotoAlternateOutlinedIcon />
                    </IconButton>
                  }
                />
              </Stack>
            </Stack>
          }
          content={
            <Stack gap={1.5}>
              <Stack flexWrap="wrap" flexDirection="row" gap={1} mb={1}>
                {photos.map((p, idx) => (
                  <PhotoItem
                    key={idx}
                    imgUrl={p.imageUrl}
                    onDelete={
                      isViewMode ? undefined : () => handleDeleteFile(p.id)
                    }
                    onClick={() => handleShowPhotoView(idx)}
                  />
                ))}

                {isLoading && <LoadingBox />}
              </Stack>

              <PhotoErrors errors={errors} />
            </Stack>
          }
        />
      </Box>

      {isShowPhotoView && (
        <PhotoView
          photos={photoGalleries}
          onClose={() => setShowPhotoView(false)}
          selectedIndex={selectedPhotoIndex}
        />
      )}
    </>
  );
};

export default AdditionalPhotos;
