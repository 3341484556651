import { Stack } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useAuthContext } from '@/context/Authentication.context';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';

const QRCode = () => {
  const { accessToken } = useAuthContext();

  const { qcData: { uuid = '' } = {} } = useQualityControlTool();

  const siteUrl = 'https://qc.backoffice.staging.vestiairecollective.com/';
  return (
    <Stack alignItems="center" py={5}>
      <QRCodeSVG
        size={250}
        value={`${siteUrl}take-photo?uuid=${uuid}&token=${accessToken}`}
      />
    </Stack>
  );
};

export default QRCode;
