import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { SCAN_PRODUCT } from '@/helpers/query-keys';
import { RoutesMapping } from '@/helpers/routing';
import { scanProduct } from '@/services/qc.service';
import { CustomError } from '@/types/AxiosError';
import { QcInfoType } from '@/types/QcInfo';

const useQcQuery = (productId: string) => {
  const { pathname } = useLocation();

  const isHomePage = pathname === RoutesMapping.HOME;

  return useQuery<QcInfoType[], CustomError>({
    // Do not put isHomePage as queryKey dependency, it will cause auto refetch on
    // page transition between homepage and qc tool page
    queryKey: [SCAN_PRODUCT, productId],
    queryFn: () => scanProduct(productId, isHomePage),
    retry: false,
    enabled: !!productId,
  });
};

export default useQcQuery;
