import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  QC_DECISION_STATE,
  QC_DECISION_STATE_TO_STATUS_MAPPING,
  QC_PRODUCT_STATUS,
  QC_PRODUCT_STATUS_MAPPING,
} from '@/helpers/states';
import { QcInfoType } from '@/types/QcInfo';
import { getUserNameFromEmail } from '@/utils/helpers';
import { formatUtcTime } from '@/utils/time';
import { Theme } from '../Theme';

const MODAL_PX = 3; //24px

interface SecondQcNoticeProps {
  firstQcData: QcInfoType;
  secondQcDecisionState: QC_DECISION_STATE;
}

const SecondQcNotice = ({
  firstQcData,
  secondQcDecisionState,
}: SecondQcNoticeProps) => {
  const { t } = useTranslation();

  const {
    state: fstQcState,
    agent: fstAgent,
    lastAgentActionDate,
  } = firstQcData;

  const getColor = (status?: string) => {
    switch (status) {
      case QC_PRODUCT_STATUS.OK:
        return Theme.palette.success.main;
      case QC_PRODUCT_STATUS.KO:
        return Theme.palette.error.main;
      case QC_PRODUCT_STATUS.NEGO:
        return Theme.palette.warning.main;
      default:
        return Theme.palette.common.black;
    }
  };

  const firstQcStatus = QC_PRODUCT_STATUS_MAPPING[
    fstQcState
  ] as QC_PRODUCT_STATUS;

  const secondQcStatus =
    QC_DECISION_STATE_TO_STATUS_MAPPING[secondQcDecisionState];

  return (
    <Stack mx={-MODAL_PX} mb={-MODAL_PX}>
      <Stack flexDirection="row" gap={4} px={MODAL_PX}>
        <Stack>
          <Typography variant="body1">
            {t('QC_V2.MAIN.CONFIRM_2ND_NOTICE_PREV_CHOICE')}
          </Typography>

          <Typography variant="h3" color={getColor(firstQcStatus)}>
            {firstQcStatus.toUpperCase()}
          </Typography>

          {!!fstAgent && (
            <Stack gap={0.5} mt={1.5}>
              <Typography variant="body2">
                {`By ${getUserNameFromEmail(fstAgent.email) || 'N/A'}`}
              </Typography>
              <Typography variant="body2">
                {fstAgent.warehouse?.name || '-'}
              </Typography>
              <Typography variant="body2">
                {formatUtcTime(lastAgentActionDate) || '-'}
              </Typography>
            </Stack>
          )}
        </Stack>

        <Stack>
          <Typography variant="body1">
            {t('QC_V2.MAIN.CONFIRM_2ND_NOTICE_CUR_CHOICE')}
          </Typography>
          <Typography variant="h3" color={getColor(secondQcStatus)}>
            {secondQcStatus ? secondQcStatus.toUpperCase() : 'N/A'}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        mt={2}
        p={2}
        sx={{ background: (theme) => theme.palette.borderColor.light }}
      >
        <Typography variant="body1">
          {t('QC_V2.MAIN.CONFIRM_2ND_NOTICE_ATTENTION')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SecondQcNotice;
