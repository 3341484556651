import { Trans } from 'react-i18next';
import { Theme } from '../Theme';

export default function ProhibitionKoContent() {
  return (
    <Trans i18nKey="QC_V2.MAIN.CONFIRM_ITEM_PROHIBITED">
      The defect you are logging will lead to prohibition. We will mark the item
      <strong
        key="confirm_prohibited"
        style={{ color: Theme.palette.error.main }}
      >
        Not compliant
      </strong>
      instantly and the process will be completed.
    </Trans>
  );
}
