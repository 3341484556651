import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useStandbyReasons } from '@/hooks/use-standbyReasons';
import { StandbyReasonType } from '@/types/StandbyReasons';

const StandbyReasonSelect = forwardRef((_props, ref) => {
  const [value, setValue] = useState<StandbyReasonType[]>([]);
  const [inputVal, setInputVal] = useState<string>('');
  const { standbyReasons } = useStandbyReasons();
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return { standbyReasons: value };
    },
  }));

  return (
    <Autocomplete
      multiple
      fullWidth
      disablePortal
      id="standby-reason-select"
      options={standbyReasons || []}
      getOptionLabel={(option) => option.name || ''}
      value={value}
      inputValue={inputVal}
      onInputChange={(_event, newInputValue) => {
        setInputVal(newInputValue);
      }}
      onChange={(_event, newValue) => {
        setValue(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('QC_V2.MAIN.ONHOLED_REASON_LABEL')}
          placeholder={t('QC_V2.MAIN.ONHOLED_REASON_PLACEHOLDER')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            {...optionProps}
            dd-custom-action={`${t('QC_V2.MAIN.ONHOLED_REASON_LABEL')}: ${option.name || 'Unknown (Cannot get selected value)'}`}
          >
            {option.name}
          </Box>
        );
      }}
    />
  );
});

StandbyReasonSelect.displayName = 'StandbyReasonSelect';

export default StandbyReasonSelect;
