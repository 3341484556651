import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { GeneralInfo } from '@/components/ProductInfo/GeneralInfo';
import { QcPanel } from '@/components/QcPanel/QcPanel';
import QcPanelModal from '@/components/QcPanelModal/QcPanelModal';
import { ModalProvider } from '@/context/Modal.context';
import {
  useQualityControlTool,
  SetDefectPayloadType,
} from '@/context/Qualitycontrol.context';
import { UploadedFileType } from '@/hooks/defect-details/use-upload-additional-photos';
import { useQcMode } from '@/hooks/use-qcMode';
import { QcInfoRecordType } from '@/types/QcInfo';
import './index.scss';

const ProductInfoGrid = styled(Grid)`
  &.MuiGrid-item {
    padding: 2rem 1rem 2rem 2rem;
    overflow-x: hidden;
  }
`;

const QcPanelGrid = styled(Grid)`
  &.MuiGrid-item {
    padding-left: 0;
  }
`;

interface QcToolContentProps {
  selectQcRound: (round: number) => void;
}

const QcToolContent = ({ selectQcRound }: QcToolContentProps) => {
  const { isViewMode } = useQcMode();

  const { qcData } = useQualityControlTool();

  const {
    setAdditionalItems,
    setDefect,
    setHallmarks,
    setWeight,
    setRefurbishment,
    setWorkingWatch,
    setAgentComment,
    setAgentPublicComment,
    updateQCPhotos,
  } = useQualityControlTool();

  useEffect(() => {
    // If page is in view mode, allow users to view the page content
    if (isViewMode) {
      return;
    }

    const { product, record } = qcData || {};

    if (product) {
      setHallmarks(product.hallmarks || '');
      setWeight(String(product.weight || ''));
    }

    // In case ON-HOLD, should show last uploaded photos
    if (
      record?.publicAdditionalPhotoUrls?.length ||
      record?.internalAdditionalPhotoUrls?.length
    ) {
      addQCPhotos(record);
    }
  }, [qcData]);

  useEffect(() => {
    // For qc result view page, set qc info to the fields
    const { record } = qcData || {};

    if (!record) return;

    const recordsDetails = (record.checkpointRecords || []).reduce(
      (acc, checkpointRecords) => {
        const defectRecords = checkpointRecords.defectRecords || [];

        const recordsInfo = defectRecords.map((item) => {
          return {
            ...item,
            checkPointId: checkpointRecords.checkpoint.id,
          };
        });

        return acc.concat(...recordsInfo);
      },
      [] as SetDefectPayloadType['data']
    );

    setDefect({
      data: recordsDetails || [],
    });
    setAdditionalItems(
      (record.additionalItemRecords || []).map((item) => item.additionalItem)
    );

    if (isViewMode) {
      setHallmarks(record.hallmarks || '');
      setWeight(String(record.weight || ''));
    }

    setRefurbishment(record.isRefurbished);
    setWorkingWatch(record.isFunctioned);
    setAgentComment(record.agentComment || '');
    setAgentPublicComment(record.agentPublicComment || '');

    addQCPhotos(record);
  }, [qcData, isViewMode]);

  const addQCPhotos = useCallback(
    (record: QcInfoRecordType) => {
      const {
        internalAdditionalPhotoUrls,
        internalAdditionalPhotos,
        publicAdditionalPhotoUrls,
        publicAdditionalPhotos,
      } = record || {};

      if (publicAdditionalPhotoUrls) {
        const publicPhotos: UploadedFileType[] = publicAdditionalPhotoUrls.map(
          (path, idx) => ({
            id: idx.toString(),
            imageUrl: path,
            imagePath: publicAdditionalPhotos?.[idx] || '',
          })
        );
        updateQCPhotos(publicPhotos, 'public');
      }

      if (internalAdditionalPhotoUrls) {
        const internalPhotos: UploadedFileType[] =
          internalAdditionalPhotoUrls.map((path, idx) => ({
            id: idx.toString(),
            imageUrl: path,
            imagePath: internalAdditionalPhotos?.[idx] || '',
          }));
        updateQCPhotos(internalPhotos, 'internal');
      }
    },
    [updateQCPhotos]
  );

  return (
    <ModalProvider>
      <Grid container spacing={2} className="QualityControl_tool">
        <ProductInfoGrid
          item
          md={4}
          className="QualityControl_tool__productInfo"
          sx={(theme) => ({
            height: 'calc(100vh - 2.75rem)',
            overflow: 'scroll',
            scrollbarWidth: 'thin',
            backgroundColor: theme.palette.background.paper,
          })}
        >
          <GeneralInfo />
          {/* This box is for the space of the icon menu */}
          <Box sx={{ height: 45 }} />
        </ProductInfoGrid>

        <QcPanelGrid item md={8}>
          <QcPanel selectQcRound={selectQcRound} />
        </QcPanelGrid>
      </Grid>
      <QcPanelModal />
    </ModalProvider>
  );
};

export default QcToolContent;
