import { Grid } from '@mui/material';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { checkRequired } from '@/helpers/defect-form';
import { SelectOptions } from '@/types/interfaces/form';
import FormAutocomplete from '../../Common/Form/FormAutocomplete';
import { DynamicFormValues } from '../DefectDetails';

interface ModelFormProps {
  control: Control<DynamicFormValues>;
  selectOptions: SelectOptions;
  disabled: boolean;
  isRequired?: boolean;
}

const ModelForm = ({
  control,
  selectOptions,
  disabled,
  isRequired,
}: ModelFormProps) => {
  const { t } = useTranslation();

  const required = isRequired ? t('QC_V2.DEFECT.REQUIRED') : undefined;

  const isHidden = !selectOptions.categoryBrandModels.length;

  if (isHidden) return null;

  return (
    <Grid item xs={12}>
      <FormAutocomplete
        control={control}
        name="categoryBrandModel"
        label={t('QC_V2.DEFECT.MODEL')}
        rules={{
          required: checkRequired(required, selectOptions.categoryBrandModels),
        }}
        autocompleteProps={{
          options: selectOptions.categoryBrandModels,
          disabled: disabled,
          isOptionEqualToValue: (option, value) => option.value === value.value,
        }}
      />
    </Grid>
  );
};

export default ModelForm;
