import { Fragment, useCallback, useMemo } from 'react';
import { Control } from 'react-hook-form';
import BrandForm from '@/components/DefectDetailModal/BasicInfoForms/BrandForm';
import CategoryForm from '@/components/DefectDetailModal/BasicInfoForms/CategoryForm';
import ColorForm from '@/components/DefectDetailModal/BasicInfoForms/ColorForm';
import DefectImportanceForm from '@/components/DefectDetailModal/BasicInfoForms/DefectImportanceForm';
import DimensionForm from '@/components/DefectDetailModal/BasicInfoForms/DimensionForm';
import MaterialForm from '@/components/DefectDetailModal/BasicInfoForms/MaterialForm';
import ModelForm from '@/components/DefectDetailModal/BasicInfoForms/ModelForm';
import SizeForm from '@/components/DefectDetailModal/BasicInfoForms/SizeForm';
import WeightForm from '@/components/DefectDetailModal/BasicInfoForms/WeightForm';
import { DynamicFormValues } from '@/components/DefectDetailModal/DefectDetails';
import { getDynamicFields, toSortedDynamicFields } from '@/helpers/defect-form';
import {
  REQUIRED_FIELD,
  SortDynamicFieldType,
} from '@/types/interfaces/defect-details';
import { useSelectOptions } from './use-select-options';
import { useQcMode } from '../use-qcMode';

interface DynamicDefectProps {
  control: Control<DynamicFormValues>;
  requiredFields?: REQUIRED_FIELD[];
  optionalFields?: REQUIRED_FIELD[];
}

export const useDynamicDefects = ({
  control,
  requiredFields,
  optionalFields,
}: DynamicDefectProps) => {
  const { isViewMode: isPageViewMode } = useQcMode();

  const respondedFields = useMemo(() => {
    const result = [];

    if (requiredFields && requiredFields.length) result.push(...requiredFields);
    if (optionalFields && optionalFields.length) result.push(...optionalFields);

    return result;
  }, [requiredFields, optionalFields]);

  const { options: selectOptions, isLoading } = useSelectOptions({
    requiredFields: respondedFields,
  });

  const dynamicFieldMapping = useCallback(
    (isRequired: boolean): Record<REQUIRED_FIELD, JSX.Element> | null => {
      if (isLoading) return null;
      return {
        [REQUIRED_FIELD.MODEL]: (
          <ModelForm
            key={REQUIRED_FIELD.MODEL}
            control={control}
            selectOptions={selectOptions}
            disabled={isPageViewMode}
            isRequired={isRequired}
          />
        ),
        [REQUIRED_FIELD.MATERIAL]: (
          <MaterialForm
            key={REQUIRED_FIELD.MATERIAL}
            control={control}
            selectOptions={selectOptions}
            disabled={isPageViewMode}
            isRequired={isRequired}
          />
        ),
        [REQUIRED_FIELD.BRAND]: (
          <BrandForm
            key={REQUIRED_FIELD.BRAND}
            control={control}
            disabled={isPageViewMode}
            isRequired={isRequired}
          />
        ),
        [REQUIRED_FIELD.CATEGORY]: (
          <CategoryForm
            key={REQUIRED_FIELD.CATEGORY}
            control={control}
            selectOptions={selectOptions}
            disabled={isPageViewMode}
            isRequired={isRequired}
          />
        ),
        [REQUIRED_FIELD.SIZE]: (
          <SizeForm
            key={REQUIRED_FIELD.SIZE}
            control={control}
            selectOptions={selectOptions}
            disabled={isPageViewMode}
            isRequired={isRequired}
          />
        ),
        [REQUIRED_FIELD.DIMENSION]: (
          <DimensionForm
            key={REQUIRED_FIELD.DIMENSION}
            control={control}
            disabled={isPageViewMode}
            isRequired={isRequired}
          />
        ),
        [REQUIRED_FIELD.IMPORTANCE]: (
          <DefectImportanceForm
            key={REQUIRED_FIELD.IMPORTANCE}
            control={control}
            selectOptions={selectOptions}
            disabled={isPageViewMode}
            isRequired={isRequired}
          />
        ),
        [REQUIRED_FIELD.COLOR]: (
          <ColorForm
            key={REQUIRED_FIELD.COLOR}
            control={control}
            selectOptions={selectOptions}
            disabled={isPageViewMode}
            isRequired={isRequired}
          />
        ),
        [REQUIRED_FIELD.WEIGHT]: (
          <WeightForm
            key={REQUIRED_FIELD.WEIGHT}
            control={control}
            disabled={isPageViewMode}
            isRequired={isRequired}
          />
        ),
        [REQUIRED_FIELD.PICTURE]: (
          <Fragment key={REQUIRED_FIELD.PICTURE}></Fragment>
        ),
        [REQUIRED_FIELD.LOCATION]: (
          <Fragment key={REQUIRED_FIELD.LOCATION}></Fragment>
        ),
      };
    },
    [control, selectOptions, isLoading, isPageViewMode]
  );

  const fields = useMemo(() => {
    const result: SortDynamicFieldType = [];

    if (requiredFields?.length) {
      const mappingFields = dynamicFieldMapping(true);

      if (mappingFields) {
        const rf = getDynamicFields(requiredFields, mappingFields);
        result.push(...rf);
      }
    }

    if (optionalFields?.length) {
      const mappingFields = dynamicFieldMapping(false);

      if (mappingFields) {
        const optFields = getDynamicFields(optionalFields, mappingFields);
        result.push(...optFields);
      }
    }

    return toSortedDynamicFields(result);
  }, [requiredFields, optionalFields, dynamicFieldMapping]);

  return { fields, selectOptions };
};
