import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getStatusMapping, QC_PRODUCT_STATE } from '@/helpers/states';

export type ErrorType = {
  field: 'weight' | 'hallmarks' | 'watch';
  message: string;
};

interface PanelHeaderItemProps {
  state: QC_PRODUCT_STATE;
  isProhibited?: boolean;
  is2ndQc?: boolean;
}

const PanelHeaderItem = ({
  state,
  is2ndQc,
  isProhibited,
}: PanelHeaderItemProps) => {
  const { t } = useTranslation();

  const statusMapped = getStatusMapping(t);

  return (
    <Stack flexDirection="row" gap={1} alignItems="center">
      <Typography
        variant="h5"
        sx={{
          fontSize: '1.25rem',
          fontWeight: '700',
        }}
        data-testid="qc_1st"
      >
        {is2ndQc ? t('QC_V2.MAIN.2ND_QC_LABEL') : t('QC_V2.MAIN.1ST_QC_LABEL')}
      </Typography>

      {!!statusMapped[state] && (
        <Typography
          data-testid="qc_status"
          variant="body2"
          sx={{
            padding: '4px 12px',
            borderRadius: '0.25rem',
            color: statusMapped[state].color,
            backgroundColor: statusMapped[state].background || 'white',
            border: `1px solid ${statusMapped[state].color}`,
            fontSize: '0.875rem',
            fontWeight: '500',
          }}
        >
          {statusMapped[state].label.toUpperCase()}
        </Typography>
      )}

      {isProhibited && (
        <Typography
          data-testid="qc_status_prohibition"
          variant="body2"
          sx={{
            padding: '4px 12px',
            backgroundColor: 'white',
            borderRadius: '0.25rem',
            color: '#F44336',
            border: '1px solid #F44336',
          }}
        >
          {t('QC_V2.MAIN.KO_PROHIBITION')?.toUpperCase()}
        </Typography>
      )}
    </Stack>
  );
};

export default PanelHeaderItem;
