import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useWarehouses } from '@/hooks/use-warehouse';
import { WarehouseType } from '@/types/Warehouse';

const WarehouseSelect = forwardRef((_props, ref) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<WarehouseType | null>(null);
  const [inputVal, setInputVal] = useState<string>('');
  const { warehouses } = useWarehouses();

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return { warehouse: value };
    },
  }));

  return (
    <Autocomplete
      fullWidth
      disablePortal
      id="warehouse-select"
      options={warehouses || []}
      getOptionLabel={(option) => option.name || ''}
      getOptionKey={(option) => option.legacyId}
      value={value}
      inputValue={inputVal}
      onInputChange={(_event, newInputValue) => {
        setInputVal(newInputValue);
      }}
      isOptionEqualToValue={(option, value) =>
        option.legacyId === value.legacyId
      }
      sx={{
        color: (theme) => theme.palette.text.primary,
        backgroundColor: (theme) => theme.palette.background.default,
      }}
      onChange={(_event, newValue) => {
        setValue(newValue as WarehouseType);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('QC_V2.QC_LST.WAREHOUSE')}
          placeholder={t('QC_V2.QC_LST.WAREHOUSE_PLACEHOLDER')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            {...optionProps}
            dd-custom-action={`${t('QC_V2.QC_LST.WAREHOUSE')}: ${option.name || 'Unknown (Cannot get selected value)'}`}
          >
            {option.name}
          </Box>
        );
      }}
    />
  );
});

WarehouseSelect.displayName = 'WarehouseSelect';

export default WarehouseSelect;
