import { datadogRum } from '@datadog/browser-rum';
import { NavigateFunction } from 'react-router-dom';

export const RoutesMapping = {
  HOME: '/',
  QC_TOOL: '/quality-control',
  QC_LIST: '/list-view',
  AGENT_PRODUCTIVITY: '/productivity',
};

export const openQCView = (productId: string, additionalParams?: string) => {
  if (window) {
    window.open(
      `${RoutesMapping.QC_TOOL}/${productId}?mode=view${additionalParams || ''}`,
      '_blank',
      'noopener noreferrer'
    );
  }
};

export const toQCScreen = (productId: number, navigate: NavigateFunction) => {
  if (!productId) return;

  datadogRum.addAction(`Go to QC ${productId}`, {
    id: productId,
  });

  navigate(`${RoutesMapping.QC_TOOL}/${productId}`);
};

export const toHomeScreen = (navigate: NavigateFunction) => {
  navigate(RoutesMapping.HOME, { replace: true });
};
