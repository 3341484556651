import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Stack } from '@mui/material';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import TakeOverToastContent from '@/components/Scanning/TakeOverToastContent';
import { SCAN_ALERT_TYPE, ToastType } from '@/types/Toast';

export const getToast = (translate: TFunction<'translation', undefined>) => {
  return {
    [SCAN_ALERT_TYPE.NO_QC_FOUND]: (productId: string): ToastType => ({
      title: translate('QC_V2.SCAN.ERROR_MESSAGE'),
      icon: <WarningAmberIcon />,
      message: translate('QC_V2.SCAN.MSG_NOT_FOUND', { productId }),
      color: 'warning',
      testid: 'scan_alert_error',
    }),
    [SCAN_ALERT_TYPE.QC_SUBMITTED]: (
      stateName: string,
      need2ndQC?: boolean
    ): ToastType => ({
      title: translate('QC_V2.SCAN.SUBMIT_SUCCESSFUL'),
      icon: <CheckCircleOutlineIcon />,
      message: (
        <Stack>
          <p>
            {translate('QC_V2.SCAN.MSG_RESULT_SUCCESS', {
              itemQcState: stateName,
            })}
          </p>
          {!!need2ndQC && (
            <p>
              <Trans i18nKey="QC_V2.MAIN.CONFIRM_2ND_QC_DES">
                The item required <strong>2nd QC</strong>
              </Trans>
            </p>
          )}
        </Stack>
      ),
      color: need2ndQC ? 'warning' : 'success',
      testid: 'scan_alert_success',
    }),
    [SCAN_ALERT_TYPE.ITEM_TAKEN_BY_OTHERS]: (
      agentEmail: string,
      uuid: string
    ): ToastType => ({
      title: translate('QC_V2.SCAN.ERROR_MESSAGE'),
      icon: <WarningAmberIcon />,
      message: <TakeOverToastContent uuid={uuid} agentEmail={agentEmail} />,
      color: 'warning',
      testid: 'scan_alert_error',
    }),
    [SCAN_ALERT_TYPE.QC_ALREADY_FINISHED]: (
      stateName: string,
      productId: string
    ): ToastType => ({
      title: translate('QC_V2.SCAN.ERROR_MESSAGE'),
      icon: <WarningAmberIcon />,
      message: translate('QC_V2.SCAN.MSG_PRODUCT_ON_HOLD', {
        productId,
        stateName,
      }),
      color: 'warning',
      testid: 'scan_alert_error',
    }),
    [SCAN_ALERT_TYPE.BEING_CONTROL_AN_ITEM]: (
      beingControlProductId: string
    ): ToastType => ({
      title: translate('QC_V2.SCAN.ERROR_MESSAGE'),
      icon: <WarningAmberIcon />,
      message: translate('QC_V2.SCAN.MSG_FINISH_QC_FOR_PRODUCT', {
        productId: beingControlProductId || 'N/A',
      }),
      color: 'warning',
      testid: 'scan_alert_error',
    }),
    // Server error
    [SCAN_ALERT_TYPE.SERVER_ERROR]: (message: string): ToastType => ({
      title: translate('QC_V2.SCAN.ERROR_MESSAGE'),
      icon: <WarningAmberIcon />,
      message: message,
      color: 'warning',
      testid: 'scan_alert_error',
    }),
    [SCAN_ALERT_TYPE.NEED_AUTHENTICATION]: (productId: string): ToastType => ({
      title: translate('QC_V2.SCAN.ERROR_MESSAGE'),
      icon: <WarningAmberIcon />,
      message: translate('QC_V2.SCAN.MSG_NEED_AUTHEN', { productId }),
      color: 'warning',
      testid: 'scan_alert_error',
    }),
    [SCAN_ALERT_TYPE.PRODUCT_RELISTED_ERROR]: (): ToastType => ({
      title: translate('QC_V2.SCAN.ERROR_MESSAGE'),
      icon: <WarningAmberIcon />,
      message: translate('QC_V2.SCAN.MSG_PRODUCT_RELISTED'),
      color: 'warning',
      testid: 'scan_alert_error',
    }),
  };
};
