import { Trans } from 'react-i18next';
import { Theme } from '../Theme';

export default function OkModalContent() {
  return (
    <Trans i18nKey="QC_V2.MAIN.CONFIRM_COMPLIANT">
      You approve the item as
      <strong
        key="confirm_compliant"
        style={{ color: Theme.palette.success.main }}
      >
        Compliant
      </strong>
      !
    </Trans>
  );
}
