import { datadogRum } from '@datadog/browser-rum';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorModal from '@/components/Modal/ErrorModal';
import QcModal from '@/components/Modal/Modal';
import { useProduct } from '@/hooks/use-product';
import useUpdateQcState from '@/hooks/use-update-qc-state';
import { QcInfoType } from '@/types/QcInfo';

interface TakeOverHoldItemProps {
  qcData?: QcInfoType;
}

const StartOnHoldItem = ({ qcData }: TakeOverHoldItemProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const [isChildDialogOpen, setIsChildDialogOpen] = useState<boolean>(false);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const { mutate: updateQcState, isPending } = useUpdateQcState();

  const { refetchQcQuery } = useProduct();

  const takeOverItem = () => {
    if (!qcData) return;

    datadogRum.addAction('Scan product take over hold item', {
      id: qcData.product.id,
    });

    updateQcState(
      {
        uuid: qcData.uuid,
        state: 'agent_unhold',
      },
      {
        onSuccess: ({ data }) => {
          setIsChildDialogOpen(false);
          setErrorMsg(null);

          if (!data) return;

          refetchQcQuery();
        },
        onError: (error) => {
          setIsChildDialogOpen(true);

          const errorMsg =
            error.response?.data?.['hydra:description'] || t('QC_V2.STH_WRONG');
          setErrorMsg(errorMsg);
        },
      }
    );
  };

  return (
    <>
      <QcModal
        open={isOpen}
        header=""
        content={t('QC_V2.SCAN.TAKEOVER_MODAL_CONTENT')}
        maxWidth="xs"
        id="scan-item-take-over-modal"
        actionButtons={{
          cancel: {
            text: 'No',
            onClick: () => setIsOpen(false),
          },
          confirm: {
            text: 'Yes',
            onClick: takeOverItem,
            isSubmitting: isPending,
          },
        }}
      />
      {isChildDialogOpen && (
        <ErrorModal
          actionButton={{
            text: t('QC_V2.MAIN.OK'),
            onClick: () => {
              setIsChildDialogOpen(false);
              setErrorMsg(null);
            },
          }}
          errorContent={errorMsg}
        />
      )}
    </>
  );
};

export default StartOnHoldItem;
